import React, { useState } from 'react';
import { Modal, Button, Input, Tabs } from 'antd';
const { TabPane } = Tabs;
function Info(props) {
    const { isInfoModalVisible, setInfoModalVisible, infoText1, infoText2, infoHeader, fromCreatingInvoice } = props
    const [isRegisterVisible, setRegisterModalVisible] = useState(false);

    const handleCancel = () => {
        setInfoModalVisible(false);
    };

    const showRegisterModal = () => {
        setRegisterModalVisible(true);
    };

    return (
        <>
            <Modal width={450} centered
                title={infoHeader} visible={isInfoModalVisible} onCancel={handleCancel} footer={""}>
                <form style={{ textAlign: "center" }}>
                    {fromCreatingInvoice ?
                        <p style={{ fontSize: "16px", color: "grey", padding: "4.6vw" }}>
{infoText1}
                        </p> : <div className="form-group">
                            <Tabs size='middle' type='card' defaultActiveKey="1" >
                                <TabPane tab="Банковская карта" key="1">
                                    <div style={{ marginTop: "5vh" }}>
                                        <p style={{ height: "20vh", fontWeight: "400" }}>
{infoText1}
                                        </p>
                                    </div>
                                </TabPane>
                                <TabPane tab="Наличные" key="2">
                                    <div style={{ marginTop: "5vh" }}>
                                        <p style={{ height: "20vh", fontWeight: "400" }}>
                                            {infoText2}
                                        </p>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>}

                    {/* <Button
                        onClick={handleCancel}
                        size="large"
                        style={{
                            display: "inherit",
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "1.75rem",
                            color: "white",
                            padding: "0 2rem",
                            backgroundColor: "#035AFC",
                        }}
                        className="sm-block"
                    >
                        Закрыть окно
                    </Button> */}
                </form>
            </Modal></>

    );
}

export default Info;

import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background-color: #ffffff;
}
ul{
  list-style: none;
  display: block;
}
a{
  text-decoration: none;
  color: inherit;
}
label{
  display: block;
  margin-bottom: 1.55rem;
  font-size: 1.5rem;
}
.form-group{
  margin-bottom: 1rem;
}
.main-page{
  position: relative;
}
`

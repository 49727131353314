import React from "react"
import { TopSection } from "./componentsStyle/showCaseStyle"

const ShowcaseTop = () => {
  return (
    <TopSection>
      <h3 className="showcase-top-title">Резервы сервиса</h3>
      <p style={{
        color: "#767C86",
        fontWeight: "300",
        fontSize: "15px",
        fontFamily: "Inter",
        fontStyle: "normal"
      }}>
        Если Вам не хватает резервов для обмена, нажмите на интересующую Вас
        валюту и отправьте нам запрос на пополнение резерва.
      </p>
    </TopSection>
  )
}

export default ShowcaseTop

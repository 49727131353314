export const theme = {
    colors: {
      white: "#ffffff",
      csblue: "#035AFC",
      red: "red",
      grey: "#BCBFC4",
      black: "#000000",
      lighter: "#F7F7F7",
      contactMute: "#767C86",
    },
  }
  
import React from "react";
// import Group101 from "./texts";
import styled from "styled-components";
// import { InterLightRollingStone16px } from "../../../styledMixins";
import "./Frame8.css";
import { useViewPort } from "../../../hooks/Viewport"
import { Collapse, Row, Col } from 'antd';

export const CoinListing = styled.div`
  margin-top: 6rem;
  margin-left: 10vw;
  margin-bottom: 9.375rem;
  padding: 0 3rem;
  display: block;
  width: 80%;

  @media (max-width: 850px) {
    padding: 0 1.25rem;
  }

  @media (max-width: 810px) {
    margin-top: 5rem;
  }

  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .coin-list-container {
    max-width: 68rem;
    margin: auto;
    display: block;
    @media (max-width: 810px) {
      max-width: unset;
      width: 100%;
    }
  }
`

const { Panel } = Collapse;

function Frame8(props) {
  const { width } = useViewPort()
  const breakpoints = 810
  const {
    text1,
    text_Label,
    group1011Props,
    group1012Props,
    group1013Props,
    group1014Props,
    group1015Props,
    group1016Props,
    group1017Props,
    group1018Props,
    group1019Props,
    group10110Props,
    group10111Props,
    group10112Props,
  } = props;

  return width < breakpoints ? <>
    <Row style={{ height: "8vh" }}></Row>
    <Row style={{ height: "35vh" }}>
      <Col span={2}></Col>
      <Col span={21}>
        <h1 style={{ color: "#1E2839" }}>{text1}</h1>
        <p style={{ color: "#767C86" }}>{text_Label}</p>
      </Col>
    </Row>
    <Row style={{ padding: "2vw" }}>
      <Collapse style={{ padding: "1vw", backgroundColor: "white" }} accordion={false} bordered={false} defaultActiveKey={['1']}>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1011Props.text2} key="1">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1011Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1012Props.text2} key="2">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1012Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1013Props.text2} key="3">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1013Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1014Props.text2} key="4">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1014Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1015Props.text2} key="5">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1015Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1016Props.text2} key="6">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1016Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1017Props.text2} key="7">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1017Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1018Props.text2} key="8">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1018Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group1019Props.text2} key="9">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group1019Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group10110Props.text2} key="10">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group10110Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group10111Props.text2} key="11">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group10111Props.text3}</p>
        </Panel>
        <Panel style={{ fontSize: "18px" }} showArrow={false} header={group10112Props.text2} key="12">
          <p style={{ fontSize: "14px", color: "#767C86" }}>{group10112Props.text3}</p>
        </Panel>
      </Collapse>
    </Row> </> :
    <CoinListing >
      <div>
        <div>
          {width > breakpoints ?
            <h1 style={{ marginLeft: "40%", width: "20vw" }}>{text1}</h1> :
            <h1 style={{ color: "#1E2839" }}>{text1}</h1>}
          <p>{text_Label}</p>
        </div>
        <div>
          <h3>{group1011Props.text2}</h3>
          <p>{group1011Props.text3}</p>
        </div>
        <div>
          <h3>{group1012Props.text2}</h3>
          <p>{group1012Props.text3}</p>
        </div>
        <div>
          <h3>{group1013Props.text2}</h3>
          <p>{group1013Props.text3}</p>
        </div>
        <div>
          <h3>{group1014Props.text2}</h3>
          <p>{group1014Props.text3}</p>
        </div>
        <div>
          <h3>{group1015Props.text2}</h3>
          <p>{group1015Props.text3}</p>
        </div>
        <div>
          <h3>{group1016Props.text2}</h3>
          <p>{group1016Props.text3}</p>
        </div>
        <div>
          <h3>{group1017Props.text2}</h3>
          <p>{group1017Props.text3}</p>
        </div>
        <div>
          <h3>{group1018Props.text2}</h3>
          <p>{group1018Props.text3}</p>
        </div>

        <div>
          <h3>{group1019Props.text2}</h3>
          <p>{group1019Props.text3}</p>
        </div>
        <div>
          <h3>{group10110Props.text2}</h3>
          <p>{group10110Props.text3}</p>
        </div>
        <div>
          <h3>{group10111Props.text2}</h3>
          <p>{group10111Props.text3}</p>
        </div>
        <div>
          <h3>{group10112Props.text2}</h3>
          <p>{group10112Props.text3}</p>
        </div>
      </div>
    </CoinListing>
    ;
}

export default Frame8;

import React, { useState } from "react"
import {
  Showcase,
  ShowcaseImg,
  DarkOverlay,
  ShowcaseConvert,
  DarkOverlayConvert
} from "./componentsStyle/showCaseStyle"
import { Button, Card, Input, Select, Row, Col } from "antd"
import LoginModel from '../models/loginModel';
import {
  Border1pxGray,
  InterLightEbonyClay16px,
  InterNormalEbonyClay24px,
} from "../styledMixins";
import styled from "styled-components";
import { useViewPort } from "../hooks/Viewport"

const { Option } = Select

const ShowCase = ({ user }) => {
  const { width } = useViewPort()
  const breakpoints = 810
  const [fromCoin, setFrom] = useState();
  const [toCoin, setTo] = useState();
  const [totalSum, setSum] = useState();
  const [fromAmount, setFromAmount] = useState();
  const [coins] = useState(LoginModel.getCoinsFromLocalStorage());
  const [screen, changeScreenTo] = useState("default");
  const [cardNumber, setCardNumber] = useState();
  const [userName, setUserName] = useState();
  const [addressCoin, setAddressCoin] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  LoginModel.moneyCurrency();

  const onCreateInvoice = async () => {
    const { error } = await LoginModel.createInvoice(email, userName, cardNumber, addressCoin, totalSum, fromCoin, fromAmount);
    if (error) return setError(error);
    changeScreenTo("done");
  }
  const onChangeFrom = (value) => {
    setFrom(value);
    const { result } = LoginModel.onCryptoCountChange(value, fromAmount, toCoin);
    setSum(result);
  }

  const onChangeGet = (value) => {
    setTo(value)
    const { result } = LoginModel.onCryptoCountChange(fromCoin, fromAmount, value);
    setSum(result);
  }

  const onClickChange = () => {
    if (fromCoin && toCoin && fromAmount) {
      changeScreenTo("convert");
    }
  }

  const onSearch = (value) => { }
  return (
    <Showcase>
      {screen === "default" ?
        <ShowcaseImg>
          <DarkOverlay>
            <div className="showcase-container">
              <div className="showcase-container-grid">
                <div className="showcase-left-grid">
                  <h1 style={{
                    fontFamily: "Inter", fontSize: "40px", fontWeight: "500", lineHeight: "110%",
                    fontStyle: "normal"
                  }} className="showcase-grid-title">
                    Сервис обмена криптовалют
                  </h1>
                  <p style={{
                    color: width < breakpoints ? "#767C86" : "#FFFFFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: width < breakpoints ? "300" : "200",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left"
                  }}>
                    Все обмены проходят круглосуточно в автоматическом
                    и полуавтоматическом режимах, с многоуровневой системой
                    серверной защиты
                  </p>
                </div>

                <div className="showcase-right-grid">
                  <Card
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <form className="pushinp">
                      <div className="form-group">
                        <label className="otdaete" htmlFor="country">Отдаете</label>
                        <div className="form-inner-group">
                          <Select
                            showSearch
                            size="large"
                            style={{ width: "100%", marginBottom: "1rem" }}
                            placeholder="Выберите валюту"
                            optionFilterProp="children"
                            onChange={onChangeFrom}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {coins ? <>
                              {coins.map(c => {
                                return (
                                  <Option key={c.id} value={c.id}>{c.id}</Option>
                                )
                              })}
                              <Option key={0} value="USD">USD</Option>
                              <Option key={1} value="RUB">RUB</Option>
                              <Option key={2} value="EURO">EURO</Option>
                            </> : <>
                              <Option key={0} value="Bitcoin">Bitcoin</Option>
                              <Option key={1} value="Ethereum">Ethereum</Option>
                              <Option key={2} value="Tether">Tether</Option>
                              <Option key={3} value="Binance Coin">Binance Coin</Option>
                            </>}
                          </Select>
                          <Input
                            onChange={(e) => {
                              setFromAmount(e.target.value)
                              const { result } = LoginModel.onCryptoCountChange(fromCoin, e.target.value, toCoin);
                              setSum(result);
                            }}
                            type="number"
                            className="input-form-control"
                            placeholder="Введите сумму"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="country">Получаете</label>
                        <div className="form-inner-group">
                          <Select
                            showSearch
                            size="large"
                            style={{ width: "100%", marginBottom: "1rem" }}
                            placeholder="Выберите валюту"
                            optionFilterProp="children"
                            onChange={onChangeGet}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {coins ? <>
                              <Option key={0} value="USD">USD</Option>
                              <Option key={1} value="RUB">RUB</Option>
                              <Option key={2} value="EURO">EURO</Option>
                              {coins.map(c => {
                                return (
                                  <Option key={c.id} value={c.id}>{c.id}</Option>
                                )
                              })}
                            </> : <>
                              <Option key={0} value="USD">USD</Option>
                              <Option key={1} value="RUB">RUB</Option>
                              <Option key={2} value="EURO">EURO</Option>
                            </>}

                          </Select>
                          <Input
                            disabled
                            type="text"
                            className="input-form-control"
                            placeholder={totalSum ? totalSum : "Сумма"}
                          />
                        </div>
                      </div>
                      <Button
                        type="primary"
                        size="large"
                        onClick={onClickChange}
                        style={{
                          width: "100%",
                          backgroundColor: "#035AFC",
                        }}
                      >
                        Обменять валюту
                      </Button>
                    </form>
                  </Card>
                </div>
              </div>
            </div>
          </DarkOverlay>
        </ShowcaseImg> : screen === "convert" ?
          width > breakpoints ? <ShowcaseConvert>
            <DarkOverlayConvert>
              <div style={{ marginLeft: "13vw" }}>
                <Row>
                  <Col><h1 style={{ fontSize: "72px" }}>{fromCoin}</h1></Col>
                </Row>
                <Row >
                  <Col span={10}>
                    <Group128 {...group128Data} fromCoin={fromCoin} toCoin={toCoin} fromAmount={fromAmount} totalSum={totalSum} changeScreenTo={changeScreenTo}></Group128>
                  </Col>
                  <Col span={3}></Col>
                </Row>
              </div>
            </DarkOverlayConvert>
          </ShowcaseConvert> :
            <ShowcaseConvert>
              {/* Convert mobile version*/}
              <Row>
                <p style={{ fontSize: "25px", marginTop: "4vh", fontWeight: "500" }}>Введите данные</p>
              </Row>
              <Row>
                <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300" }}>
                  Номер карты отправителя*
                </label>
                <Input
                  size="small"
                  type="number"
                  onChange={(e) => setCardNumber(e.target.value)}
                  placeholder="**** **** **** ****"
                  className="input-form-control"
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    padding: "0",
                    fontWeight: "500",
                    fontSize: "18px",
                    borderTop: "none",
                    marginTop: "-1.5vh",
                    background: "transparent",
                    color: "#767C86",
                  }}
                />
              </Row>
              <Row>
                <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300", marginTop: "3vh" }}>
                  ФИО отправителя*
                </label>
                <Input
                  size="small"
                  type="text"
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Сергей Викторович Зорин"
                  className="input-form-control"
                  style={{
                    borderLeft: "none",
                    padding: "0",
                    borderRight: "none",
                    fontWeight: "500",
                    fontSize: "18px",
                    marginTop: "-1.5vh",
                    borderTop: "none",
                    background: "transparent",
                    color: "#767C86",
                  }}
                />
              </Row>
              <Row>
                <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300", marginTop: "3vh" }}>
                  Кошелек для получения*
                </label>
                <Input
                  size="small"
                  type="text"
                  onChange={(e) => setAddressCoin(e.target.value)}
                  placeholder="1616254686199491616"
                  className="input-form-control"
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    fontWeight: "500",
                    fontSize: "18px",
                    padding: "0",
                    marginTop: "-1.5vh",
                    background: "transparent",
                    color: "#767C86",
                  }}
                />
              </Row>
              <Row>
                <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300", marginTop: "3vh" }}>
                  Ваш E-mail*
                </label>
                <Input
                  size="small"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="sergeyzorin@mail.ru"
                  className="input-form-control"
                  style={{
                    borderLeft: "none",
                    padding: "0",
                    borderRight: "none",
                    fontWeight: "500",
                    fontSize: "18px",
                    borderTop: "none",
                    marginTop: "-1.5vh",
                    background: "transparent",
                    color: "#767C86",
                  }}
                />
              </Row>
              <Row>
                <Button onClick={onCreateInvoice} style={{ backgroundColor: "#035AFC", color: "white", width: "100%", marginTop: "4vh", borderRadius: "5px", height: "5.6vh" }}>Создать заявку</Button>
              </Row>
            </ShowcaseConvert>
          : screen === "done" ?
            width > breakpoints ?
              <ShowcaseConvert>
                <DarkOverlayConvert>
                  <div style={{ marginLeft: "10vw" }}>
                    <Row>
                      <Col span={14}><p style={{ fontSize: "58px" }}>Заявка создана</p></Col>
                      <Col span={2}></Col>
                      <Col span={4}><p style={{ fontSize: "58px" }}>#371</p></Col>
                    </Row>
                    <Row>
                      <hr style={{ width: "80%", textAlign: "left", marginLeft: 0 }} />
                    </Row>
                    <Row >
                      <Col span={10}>
                        <p></p>
                        <p style={{ fontSize: "18px", color: "#1E2839" }}>Инструкция по проведению операции уже в вашей почте. Проверьте папку спам!</p>
                      </Col>
                      <Col span={14}></Col>

                    </Row>
                    <Row>
                      <Col span={24}>
                        <p style={{ color: "red" }}>Важно!!!</p>
                        <p style={{ fontSize: "14px", color: "#767C86" }}>1. Мы не принимаем криптовалюту, которая ранее была задействована на торговых площадках DarkNet, игровых сервисах, а также пропущена через миксеры
                          Комиссия за возврат такой валюты составляет 0.0003 ВТС, 0.01ЕТН</p>
                        <p style={{ fontSize: "14px", color: "#767C86" }}>2. Вывод возможен только на карты РФ</p>
                        <p style={{ fontSize: "14px", color: "#767C86" }}>3. Зачисление на карту составляет как правило 1-60 минут</p>
                        <p style={{ fontSize: "14px", color: "#767C86" }}>4. По регламенту зачисление платежа на карту до 3х банковских дней</p>
                        <p style={{ fontSize: "14px", color: "#767C86" }}>5. Ваша заявка продублирована на указанную вами почту. Обязательно проверьте папку спам!</p>
                      </Col>
                    </Row>
                  </div>
                </DarkOverlayConvert>
              </ShowcaseConvert> :
              <ShowcaseConvert>
                {/* Mobile version convert done */}
                <DarkOverlayConvert>
                  <div>
                    <Row style={{ height: "4vh" }}></Row>
                    <Row>
                      <Col span={20}><p style={{ fontSize: "28px", fontWeight:"500" }}>Заявка создана</p></Col>
                      <Col span={4}><p style={{ fontSize: "22px", marginTop: "1vh" }}>#371</p></Col>
                    </Row>
                    <Row>
                      <hr style={{ width: "100%", textAlign: "left", marginLeft: 0 }} />
                    </Row>
                    <Row >
                      <Col span={24}>
                        <p></p>
                        <p style={{ fontSize: "20px", color: "#1E2839", fontWeight:"normal" }}>Инструкция по проведению операции уже в вашей почте. Проверьте папку спам!</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{fontSize: "14px", color: "#767C86"}}>
                        <p style={{ color: "red", height:"1vh" }}>Важно!!!</p>
                        1. Мы не принимаем криптовалюту, которая ранее была задействована на торговых площадках DarkNet, игровых сервисах, а также пропущена через миксеры
                          Комиссия за возврат такой валюты составляет 0.0003 ВТС, 0.01ЕТН
                        <br></br>2. Вывод возможен только на карты РФ
                        <br></br>3. Зачисление на карту составляет как правило 1-60 минут
                        <br></br>4. По регламенту зачисление платежа на карту до 3х банковских дней
                        <br></br>5. Ваша заявка продублирована на указанную вами почту. Обязательно проверьте папку спам!
                      </Col>
                    </Row>
                  </div>
                </DarkOverlayConvert>
              </ShowcaseConvert>
            : ""}
    </Showcase>
  )
}

function Group128(props) {
  const { fromAmount, price, image81, toCoin, image82, changeScreenTo, totalSum, fromCoin } = props;
  const [cardNumber, setCardNumber] = useState();
  const [userName, setUserName] = useState();
  const [addressCoin, setAddressCoin] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const onCreateInvoice = async () => {
    const { error } = await LoginModel.createInvoice(email, userName, cardNumber, addressCoin, totalSum, fromCoin, fromAmount);
    if (error) return setError(error);
    changeScreenTo("done");
  }

  return (
    <Group1281>
      <ViewContainer>
        <View>
          <View1>
            <View2>
              <OverlapGroup>
                <Address>{totalSum}</Address>
                <Price>{toCoin}</Price>
                <Rectangle5></Rectangle5>
                <Image8 src={image81} />
                <Rectangle7></Rectangle7>
              </OverlapGroup>
            </View2>
          </View1>
        </View>
        <View3>
          <View1>
            <View2>
              <OverlapGroup>
                <Address>{fromAmount}</Address>
                <RUB>{fromCoin}</RUB>
                <Rectangle5></Rectangle5>
                {/* <Image8 src={image82} /> */}
                <Rectangle7></Rectangle7>
              </OverlapGroup>
            </View2>
          </View1>
        </View3>
      </ViewContainer>
      <Group127>
        <ViewContainer1>
          <Row style={{paddingBottom:"5vh"}}>
            <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300" }}>
              Номер карты отправителя*
            </label>
            <Input
              size="small"
              type="number"
              onChange={(e) => setCardNumber(e.target.value)}
              placeholder="**** **** **** ****"
              className="input-form-control"
              style={{
                borderLeft: "none",
                borderRight: "none",
                padding:"0",
                fontSize:"18px",
                borderTop: "none",
                background: "transparent",
                color: "#1E2839",
              }}
            />
          </Row>
          <Row>
            <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300" }}>
              ФИО отправителя*
            </label>
            <Input
              size="small"
              type="text"
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Сергей Викторович Зорин"
              className="input-form-control"
              style={{
                borderLeft: "none",
                borderRight: "none",
                padding:"0",
                fontSize:"18px",
                borderTop: "none",
                background: "transparent",
                color: "#1E2839",
              }}
            />
          </Row>
          <Row style={{ marginTop: "5vh" }}>
            <p style={{ color: "red" }}>{error}</p>
            <Button onClick={onCreateInvoice} style={{ backgroundColor: "#035AFC", color: "white", borderRadius: "5px", width: "15vw", height: "5.4vh" }}>Создать заявку</Button>
          </Row>
        </ViewContainer1>
        <ViewContainer2>
          <Row style={{paddingBottom:"5vh"}}>
            <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300" }}>
              Кошелек для получения*
            </label>
            <Input
              size="small"
              type="text"
              onChange={(e) => setAddressCoin(e.target.value)}
              placeholder="1616254686199491616"
              className="input-form-control"
              style={{
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
                padding:"0",
                fontSize:"18px",
                background: "transparent",
                color: "#1E2839",
              }}
            />
          </Row>
          <Row>
            <label htmlFor="country" style={{ fontSize: "14px", color: "#767C86", fontWeight: "300", }}>
              Ваш E-mail*
            </label>
            <Input
              size="small"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sergeyzorin@mail.ru"
              className="input-form-control"
              style={{
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
                padding:"0",
                fontSize:"18px",
                background: "transparent",
                color: "#1E2839",
              }}
            />
          </Row>
        </ViewContainer2>
      </Group127>
    </Group1281>
  );
}

const View = styled.div`
  width: 26.3vw;
  height: 7.6vh;
  display: flex;
  &.view-6.view-8 {
    margin-top: 3vh;
  }        
  &.view-6.view-10 {
    margin-top: 2.9vh;
  }        
`;

const View1 = styled.div`        
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 76px;          
`;

const Group1281 = styled.div`
  width: 58.4vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 37.1vh;
`;

const ViewContainer = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 58.5vw;
`;

const View2 = styled.div`
          display: flex;
          align-items: flex-start;
          min-width: 26.5vw;
        `;

const OverlapGroup = styled.div`
          width: 27vw;
          height: 8.3vh;
          position: relative;
          border-radius: 5px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.1);
        `;

const Address = styled.h1`
          ${InterNormalEbonyClay24px}
          position: absolute;
          top: 1.6vh;
          left: 3.2vw;
          letter-spacing: 0;
          line-height: 30px;
          white-space: nowrap;
        `;

const Price = styled.div`
          ${InterLightEbonyClay16px}
          position: absolute;
          top: 2vh;
          left: 18vw;
          letter-spacing: 0;
          line-height: 21.6px;
          white-space: nowrap;
        `;

const Rectangle5 = styled.div`
          ${Border1pxGray}
          position: absolute;
          width: 26.3vw;
          height: 5.5vh;
          top: 0;
          left: 0;
          border-radius: 5px;
        `;

const Image8 = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 15px;
  top: calc(50% - 25px/2);
`;

const Rectangle7 = styled.div`
          position: absolute;
          width: 1px;
          height: 30px;
          top: 13px;
          left: 50px;
          background-color: var(--silver-sand);
        `;

const View3 = styled.div`
          width: 26.3vw;
          margin-left: 5.8vw;
          display: flex;
          align-items: flex-end;
        `;

const RUB = styled.div`
          ${InterLightEbonyClay16px}
          position: absolute;
          top: 2vh;
          left: 18vw;
          text-align: right;
          letter-spacing: 0;
          line-height: 21.6px;
          white-space: nowrap;
        `;

const Group127 = styled.div`
          height: 18.2vh;
          margin-top: 3vh;
          display: flex;
          align-items: flex-start;
          min-width: 58.4vw;
        `;

const ViewContainer1 = styled.div`
          width: 26.3vw;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-height: 18.2vh;
        `;

const ViewContainer2 = styled.div`
          width: 26.3vw;
          position: relative;
          margin-left: 5.7vw;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-height: 181px;
        `;

const group128Data = {
  address: "10 000",
  price: "USD",
  image81: "https://anima-uploads.s3.amazonaws.com/projects/61f84f092503355a0a2d7263/releases/620826e9e84195368ee52901/img/image-8@1x.png",
  x10000: "0.00229632",
  rub: "BTC",
  image82: ""//"https://bittrexblobstorage.blob.core.windows.net/public/ddbdafb2-e267-4114-abc3-06316cf3bef9.png",
};

export default ShowCase

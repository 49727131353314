import { notification } from 'antd';

const openNotificationWithIcon = type => {
    notification[type]({
        message: '',
        description:
            'Что-то пошло не так, попробуйте еще раз',
    });
};

export default openNotificationWithIcon;
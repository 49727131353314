import API from "../query";

class LoginModel {
    constructor() {
        this.dateThatCalled = new Date();
        this.starting = null;
        this.coins = [];
    }

    async login(email, password) {
        if (!email || !password) {
            return { error: "Электронная почта или пароль пустые." };
        }
        try {
            const { error, data } = await API.login(email, password);

            if (error) {
                return { error, data };
            }
            if (data.user.role === "ADMIN") {
                window.location.href = "http://localhost:4000/admin/pages/Login_v2/login.html"; //must change
                localStorage.setItem("xyz", JSON.stringify(data.authToken));// chi set anum
            } else {
                let itemToSave = {
                    email: data.user.email,
                    firstName: data.user.firstName,
                    id: data.user.id,
                    isOnline: data.user.isOnline,
                    lastName: data.user.lastName,
                    money: data.user.money,
                    paymentActivation: data.user.paymentActivation,
                    paymentSystemId: data.payment ? data.payment.paymentSystemId : "",
                    phone: data.user.phone,
                    surName: data.user.surName,
                    isDownloadP: data.user.passportId ? true : false
                }
                localStorage.setItem("us", JSON.stringify(itemToSave));
                localStorage.setItem("uscr", JSON.stringify(data.cryptos));
                localStorage.setItem("xyz", JSON.stringify(data.authToken));
                localStorage.setItem("exp", JSON.stringify(data.tokenExpiresAfter));
                window.location.href = "/profile";
            }
        } catch (error) {
            console.log("Error on login():", error);
        }
    }

    async register(regCode, password, passwordAgain, firstName, lastName, surName, email, phone) {
        if (!regCode.length) {
            return { error: "Регистрационный код пуст." };
        }

        if (password !== passwordAgain) {
            return { error: "Что-то пошло не так. Любое из полей может быть неправильным." };
        }

        if (this.validateFirstName(firstName) &&
            this.validateSurName(surName) &&
            this.validateLastName(lastName) &&
            this.validateEmail(email) &&
            this.validatePhone(phone)) {

            const { error, data } = await API.register(regCode, password, passwordAgain, firstName, lastName, surName, email, phone);
            if (error) {
                return { error, data };
            }
            const { user } = data;
            let itemToSave = {
                email: user.email,
                firstName: user.firstName,
                id: user.id,
                isOnline: user.isOnline,
                lastName: user.lastName,
                money: user.money,
                paymentActivation: user.paymentActivation,
                paymentSystemId: data.payment.paymentSystemId,
                phone: user.phone,
                surName: user.surName,
                isDownloadP: user.passportId ? true : false
            }
            localStorage.setItem("us", JSON.stringify(itemToSave));
            localStorage.setItem("xyz", JSON.stringify(data.authToken));
            localStorage.setItem("exp", JSON.stringify(data.tokenExpiresAfter));
            if (user.role === "ADMIN") {
                window.location.href = "http://localhost:4000/admin/pages/Login_v2/login.html";
            } else {
                window.location.href = "/profile";
            }
            return { error, data };
        } else {
            return { error: "Что-то пошло не так. Любое из полей может быть неправильным.", data: null };
        }
    }

    async sendRegistrationCode(email) {
        if (!this.validateEmail(email)) {
            return;
        } else {
            const { data, error } = await API.verifyEmail(email);
            return { data, error };
        }
    };

    validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    validatePasswordAgain(password, passwordAgain) {
        var pass = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,}$/;
        if (password.match(pass)) {
            if (password != passwordAgain) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }

    validatePassword(password) {
        var pass = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,}$/;
        if (password.match(pass)) {
            return true;
        }
        else {
            return false;
        }
    }

    validatePhone(phone) {
        // let phoneNo = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
        let phoneNo = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
        if (phone.match(phoneNo)) {
            return true;
        }
        else {
            return false;
        }
    }

    validateLastName(lastName) {
        if (lastName.length <= 3) {
            // document.getElementById("lastName").style.color = "red";
            return false;
        } else {
            // document.getElementById("lastName").style.color = "green";
            return true;
        }
    }

    validateFirstName(firstName) {
        if (firstName.length <= 2) {
            // document.getElementById("firstName").style.color = "red";
            return false;
        } else {
            // document.getElementById("firstName").style.color = "green";
            return true;
        }
    }

    validateSurName(surName) {
        if (!surName) {
            return false;
        } else {
            return true;
        }
    }

    async loadDataIntoTable(setCoins) {
        const { cryptos } = await API.cryptoInfo();
        let coins = [];
        cryptos.forEach(coin => {
            coins.push({
                id: coin.name,
                image_url: "./images/coins/bitcoin.svg",
                name: coin.name,
                amount: coin.price_usd,
                coin_abrv: coin.name,
            })
        });
        this.coins = coins
        setCoins(coins)
        localStorage.setItem("coins", JSON.stringify(coins));
    }

    async fillClassCoins() {
        fetch('https://api.coinlore.com/api/tickers/?start=0&limit=16')
            .then(response => response.json())
            .then(data => {
                let coins = [];
                data['data'].forEach((coin) => {
                    coins.push({
                        id: coin.name,
                        image_url: "./images/coins/bitcoin.svg",
                        name: coin.name,
                        amount: coin.price_usd,
                        coin_abrv: coin.name,
                    })
                });
                localStorage.setItem("coins", JSON.stringify(coins));
                return coins;
            })
            .catch(err => console.log(err));
    }

    async moneyCurrency() {
        const result = await API.getMoneyPricesCheckedWithDollar();
        localStorage.setItem("currency", JSON.stringify(result.data));
        return result
    }

    getCoinsFromLocalStorage() {
        return JSON.parse(localStorage.getItem("coins"));
    }

    onCryptoCountChange(fromCoin, fromCount, toCoin) {
        const data = JSON.parse(localStorage.getItem("coins"));
        const currency = JSON.parse(localStorage.getItem("currency"))
        let moneyTypePrice = null;
        switch (toCoin) {
            case 'RUB':
                moneyTypePrice = 1 / currency.RUB;
                break;
            case 'EURO':
                moneyTypePrice = 1 / currency.EUR;
                break;
            default:
                break;
        }

        let fromMoneyTypePrice = 0;
        switch (fromCoin) {
            case 'RUB':
                fromMoneyTypePrice = 1 / currency.RUB;
                break;
            case 'EURO':
                fromMoneyTypePrice = currency.EUR;
                break;
            default:
                break;
        }
        if (!fromMoneyTypePrice) {
            data.forEach(async (d) => {
                if (d.id === fromCoin) {
                    fromMoneyTypePrice = d.amount;
                }
            })
        }

        if (!moneyTypePrice && toCoin != "USD") {
            for (const c of data) {
                if (c.id == toCoin) {
                    moneyTypePrice = Number(c.amount);
                }
            }
        }

        if (toCoin == "USD") {
            moneyTypePrice = 1
        }

        let finishSum = 0;
        if (fromCoin == "EURO" && toCoin == "USD") {
            finishSum = (moneyTypePrice/fromMoneyTypePrice)*fromCount
        } else
            if (fromCoin == "USD") {
                finishSum = (fromCount * 1) / moneyTypePrice;
            }
            else {
                finishSum = (fromCount * fromMoneyTypePrice) / moneyTypePrice
            }
        return { result: finishSum }
    }

    async createInvoice(email, userName, cardNumber, addressCoin, totalSum, cryptoName, quantity) {
        console.log(email, userName, cardNumber, addressCoin, totalSum, cryptoName, quantity)
        if (!this.validateEmail(email)) {
            return;
        } else {
            const { data, error } = await API.createInvoice(email, userName, cardNumber, addressCoin, totalSum, cryptoName, quantity);
            return { data, error };
        }
    }
};

export default new LoginModel();;
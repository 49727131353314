import styled from "styled-components"

export const MobileNavigation = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 0;
  height: 100vh;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3.125rem 1.25rem;
`
export const MobileNavContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.1rem;

  .mobile-nav-box {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.csblue};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

import styled from "styled-components"

export const ContactArea = styled.div`
  width: 100%;
  padding-top: 4.375rem;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  background-color: ${({ theme }) => theme.colors.lighter};

  @media (max-width: 850px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .contact-container {
    max-width: 68rem;
    margin: auto;
    display: block;
  }
`

export const ContactFormGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 1rem;

  @media (max-width: 810px) {
    grid-template-columns: unset;
  }

  .contact-form-right {
    @media (max-width: 810px) {
      grid-row: 2;
      grid-column: 1/3;
    }
    .form-group-with-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 810px) {
        display: block;
        width: 100%;
      }

      .right-column {
        @media (max-width: 810px) {
          margin-top: 1rem;
        }
      }
    }
    .sm-block {
      @media (max-width: 810px) {
        width: 100%;
        display: block;
      }
    }
  }

  .contact-form-left {
    @media (max-width: 810px) {
      grid-column: 1/2;
      grid-row: 1;
      margin-bottom: 3rem;
    }
  }
  .contact-form-middle {
    @media (max-width: 810px) {
      grid-column: 2/3;
      grid-row: 1;
      margin-bottom: 3rem;
    }
  }

  .contact-form-left,
  .contact-form-right,
  .contact-form-middle {
    display: block;
    width: 100%;

    .contact-form-title {
      font-size: clamp(1rem, 2.5vw, 1.15rem);
      text-align: left;
    }

    .contact-form-list {
      display: block;
      width: 100%;
      margin-bottom: 0;
      text-align: left;

      .contact-form-list-item {
        font-weight: 300;
        font-size: clamp(0.875rem, 2.5vw, 1rem);
        color: ${({ theme }) => theme.colors.contactMute};
      }
    }
  }
`

import { css } from "styled-components";

export const AlignSelfFlexEnd = css`
  align-self: flex-end;
`;

export const InterNormalEbonyClay24px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl2);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalRollingStone18px = css`
  color: var(--dark-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl3);
  font-weight: 400;
  font-style: normal;
`;

export const RobotoLightEbonyClay18px = css`
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;
export const RobotoLightEbonyClay16px22 = css`
  color: var(--ebony-clay);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m2);
  font-weight: 300;
  font-style: normal;
`;
export const RobotoNormalEbonyClay24px = css`
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const RobotoNormalEbonyClay20px = css`
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const RobotoLightEbonyClay16px = css`
  color: var(--ebony-clay);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const RobotoLightEbonyClay16px2 = css`
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay18px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterLightRollingStone16px = css`
  color: var(--dark-gray);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterLightEbonyClay16px = css`
  color: var(--black);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterLightEbonyClay14px = css`
  color: var(--black);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-xs);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay16px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const InterRegularNormalRollingStone18px = css`
  color: var(--dark-gray);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay14px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const RobotoMediumWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const InterSemiBoldBlueRibbon24px = css`
  color: var(--blue);
  font-family: var(--font-family-inter-semibold);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
`;

export const RobotoLightBlueRibbon16px = css`
  color: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay24px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const InterMediumEbonyClay72px = css`
  color: var(--black);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay30px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
`;

export const InterMediumEbonyClay56px = css`
  color: var(--black);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const InterLightEbonyClay18px = css`
  color: var(--black);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const InterLightRollingStone18px = css`
  color: var(--dark-gray);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalWhite18px = css`
  color: var(--white);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterLightWhite14px = css`
  color: var(--white-2);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-xs);
  font-weight: 300;
  font-style: normal;
`;

export const RobotoLightWhite16px = css`
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const RobotoLightRollingStone16px = css`
  color: var(--dark-gray);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterMediumWhite72px = css`
  color: var(--white);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const InterLightWhite20px = css`
  color: var(--white);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-l);
  font-weight: 300;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay15px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
`;

export const InterRegularNormalEbonyClay12px = css`
  color: var(--black);
  font-family: var(--font-family-inter-regular);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
`;

export const InterMediumEbonyClay44px = css`
  color: var(--black);
  font-family: var(--font-family-inter-medium);
  font-size: 44px;
  font-weight: 500;
  font-style: normal;
`;

export const InterMediumEbonyClay32px = css`
  color: var(--black);
  font-family: var(--font-family-inter-medium);
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
`;

export const InterRegularNormalWhite14px = css`
  color: var(--white);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const RobotoMediumWhite72px = css`
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const RobotoLightWhite20px = css`
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 300;
  font-style: normal;
`;

export const InterLightWhite18px = css`
  color: var(--white);
  font-family: var(--font-family-inter-light);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const Border1pxGray = css`
  border: 1px solid var(--gray);
`;

export const Border4pxRed = css`
  border: 4px solid var(--red);
`;

export const Border1pxBlack = css`
  border: 1px solid var(--black);
`;

export const Border1pxDarkGray = css`
  border: 1px solid var(--dark-gray);
`;

export const Border1pxSilverSand = css`
  border: 1px solid var(--silver-sand);
`;

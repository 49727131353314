import API from "../query";

class ContactModel {
    async sendEmailToOwner(email, name, question) {
        try {
	if(!email){
return {data:null, error:"Напишите адрес электронной почты."}
}
            const { error, data } = await API.sendEmail(email, name, question);
            return { error, data };
        } catch (error) {
            return { error: error.message };
        }
    }
}

export default new ContactModel();

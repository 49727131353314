import React from "react"
import ShowCase from "../../components/ShowCase"
import CoinList from "../../components/CoinLists"
import Contact from "../../components/Contact"
import Footer from "../../components/Footer"

const Home = ({ setIn }) => {
  return (
    <>
      <ShowCase setIn={setIn} />
      <CoinList />
      <Contact />
      <Footer />
    </>
  )
}

export default Home

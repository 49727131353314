import React from "react"
import { FooterArea } from "./componentsStyle/footerStyle"

const Footer = () => {
  return (
    <FooterArea>
      <div className="footer-container">
        <div className="footer-links">
          <a href="#!" style={{ textDecoration: "underline", color: "#1E2839" }}>
            Политика конфиденциальности
          </a>
          <p style={{ color: "#1E2839" }}>&copy; WCE. Все права защищены.</p>
        </div>
      </div>
    </FooterArea>
  )
}

export default Footer

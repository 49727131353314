import React, { useState } from "react"
import { SmNav, SmNavMobile } from "./layoutStyles/NavBarStyles"
import { UserOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons"
import Logo from "../../assets/images/Framel.svg"
import { useViewPort } from "../../hooks/Viewport"
import LoginModal from "../modals/loginModal";
import ProfileModel from "../../models/profileModel";
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

let url = "";
let link = window.location.href;
for (let t = link.length - 1; t > 0; --t) {
  if (link[t] !== '/') url += link[t];
  if (link[t] === '/') { url += link[t]; break; }
}

let originalUrl = "";
for (let t = url.length - 1; t >= 0; --t) {
  originalUrl += url[t];
}

const NavBar = ({ menu, handleMenuShow, user, setIn, isInLogin }) => {
  const { width } = useViewPort()
  const breakpoints = 810
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);

  return <>
    <LoginModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    {width > breakpoints ?
      <SmNav>
        <ComponentContaining setIn={setIn} isInLogin={isInLogin} handleMenuShow={handleMenuShow} menu={menu} width={width} breakpoints={breakpoints} showModal={showModal}></ComponentContaining>
      </SmNav> :
      <SmNavMobile>
        <ComponentContaining setIn={setIn} isInLogin={isInLogin} handleMenuShow={handleMenuShow} menu={menu} width={width} breakpoints={breakpoints} showModal={showModal}></ComponentContaining>
      </SmNavMobile>}
  </>
}

const ComponentContaining = ({ showModal, width, breakpoints, menu, handleMenuShow, setIn, isInLogin }) => {

  return (
    <div className="nav-container">
      <div onClick={() => window.location.href = "/home"} className="logo-container">
        <div className="logo-img-container">
          <img src={Logo} alt="logo" />
        </div>
        <h3 className="logo-brand">WCE</h3>
      </div>
      <ul className="middle-nav-list">
        <li className="middle-nav-list-item">
          {isInLogin && (originalUrl == "/home" || originalUrl == "/") ? <a href="/profile">Профиль</a> : <a href="/home">Обменять валюту</a>}
        </li>
        <li className="middle-nav-list-item">
          <a href="/policy">Правила сайта</a>
        </li>
        <li className="middle-nav-list-item">
          <a href="#!">FAQ</a>
        </li>
        <li className="middle-nav-list-item">
          <a href="#!">Обратная связь</a>
        </li>
      </ul>
      {width > breakpoints ? (
        <ul className="end-nav-list">
          {isInLogin ?
            <>
              <IconFont onClick={() => ProfileModel.logOut(setIn)} type="icon-tuichu" />
              <li className="end-nav-list-item">Выйти</li>
            </>
            :
            <>
              <li className="end-nav-list-item">{originalUrl === "/profile" ? "Выйти" : "Войти"}</li>
              <div onClick={() => originalUrl === "/profile" ? ProfileModel.logOut(setIn) : showModal()} style={{borderRadius:"50%", padding: "0.2rem", cursor:"pointer", border:"1px solid", width:"30px", height:"30px"}}>
                <UserOutlined style={{fontSize:"15px", marginLeft:"0.3vw"}}/>
              </div>
            </>
          }
        </ul>
      ) : (
        <ul className="end-nav-list-mobile">
          <div onClick={() => isInLogin ? window.location.href = "/profile" : showModal()} style={{borderRadius:"50%", padding: "0.5rem", border:"1px solid", width:"40px", height:"40px"}}>
            <UserOutlined style={{fontSize:"15px", marginLeft:"1vw"}}/>
          </div>
          <div style={{borderRadius:"50%", backgroundColor:!menu?"":"#035AFC", padding: "0.6rem", border:"1px solid", width:"40px", height:"40px"}}>
            <button
              className={`menu-btn ${menu ? "btn-active" : ""}`}
              onClick={handleMenuShow}
            >
              {!menu ? <MenuOutlined style={{fontSize:"15px", marginLeft:"0.5vw"}}/> : <CloseOutlined style={{fontSize:"15px", marginLeft:"0.5vw"}}/>}
            </button>
          </div>
        </ul>
      )}
    </div>
  )
}

export default NavBar

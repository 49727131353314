
const API = {
    getUrl: () => "https://wcecrypto.herokuapp.com",
//"http://3.215.197.223:4000",
    headers: {
        'Content-Type': 'application/json',
        'authentication': `Bearer ${localStorage.getItem('authToken')}`,
    },

    verifyEmail: async (email) => {
        const response = await fetch(`${API.getUrl()}/verifyEmail`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email }),
        })
        const result = await response.json();
        return { error: result.message, data: result };
    },

    login: async (email, password, userId) => {
        const response = await fetch(`${API.getUrl()}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email, password, userId }),
        })
        const result = await response.json();
        return { error: result.message, data: result };
    },

    register: async (regCode, password, _, firstName, lastName, surName, email, phone) => {
        const response = await fetch(`${API.getUrl()}/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ regCode, email, password, firstName, lastName, surName, phone, role: "USER" }),
        });
        const result = await response.json();
        return { error: result.message, data: result };
    },

    cryptoInfo: async () => {
        const response = await fetch(`${API.getUrl()}/cryptoInfo`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const result = await response.json();
        console.log(result)
        return result;
    },

    sendEmail: async (email, name, question) => {
        const response = await fetch(`${API.getUrl()}/contact/owner/withoutRegister`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email, name, question })
        });
        const result = await response.json();
        return result;
    },

    convertMoney: async (from, to, amount) => {
        // const response = await fetch(`https://api.api-ninjas.com/v1/convertcurrency?want=${to}&have=${from}&amount=${amount}`, {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //         'X-Api-Key':"W6IruUl0qczh7Y7R/BFsQw==OMUWw23IfGEeXXef"
        //     },
        // });
        // return response;
    },

    createInvoice: async (email, userName, cardNumber, address, amount, cryptoName, quantity) => {
        const response = await fetch(`${API.getUrl()}/user/createInvoice/withoutRegister`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ email, userName, cardNumber, address, amount, cryptoName, quantity, actionType: window.user ? "MAIN SCREEN" : null, userId: JSON.parse(localStorage.getItem("us")) ? JSON.parse(localStorage.getItem("us")).id : null })
        });
        const result = await response.json();
        return result;
    },

    getMoneyPricesCheckedWithDollar: async () => {
        const response = await fetch(`https://freecurrencyapi.net/api/v2/latest?apikey=0d2532f0-9597-11ec-9e2e-39008f009cef`);
        const result = await response.json();
        return result;
    },

    withDrawMoneyType1: async (transferMoney, cardNumber, withdrawType) => {
        const response = await fetch(`${API.getUrl()}/user/createInvoice`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ amount: transferMoney, actionType: withdrawType, address: cardNumber }),
        });

        const result = await response.json();
        return result;
    },

    withDrawMoneyType2: async (bitcoinAddress, bitcoinBalance, withdrawType) => {
        const response = await fetch(`${API.getUrl()}/user/createInvoice`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ amount: bitcoinBalance, actionType: withdrawType, address: bitcoinAddress }),
        });

        const result = await response.json();
        return result;
    },

    withDrawMoneyType3: async (INN, KPP, RC, KORC, BIK, amountToTransfer, withdrawType) => {
        const response = await fetch(`${API.getUrl()}/user/createInvoice`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ amount: amountToTransfer, actionType: withdrawType, address: "BANK" }),
        });

        const result = await response.json();
        return result;
    },

    userInvoices: async () => {
        const response = await fetch(`${API.getUrl()}/user/invoices`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ isAdminInvoice: false, limit: 50, skip: 0, userId: JSON.parse(localStorage.getItem("us")).id }),
        });

        const result = await response.json();
        return result;
    },

    userCryptoValues: async () => {
        const response = await fetch(`${API.getUrl()}/cryptoValues/user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ limit: 50, skip: 0 }),
        });

        const result = await response.json();
        return result;
    },
}

export default API;

import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "./components/themes/globalStyles"
import { theme } from "./components/themes/colors"
import "antd/dist/antd.css"
import NavBar from "./components/layouts/NavBar"
import Home from "./components/pages/home"
import Change from "./components/pages/change"
import Policy from "./components/pages/policy"
import Profile from "./components/pages/profile"
import MobileNav from "./components/layouts/MobileNav"
import { useViewPort } from "./hooks/Viewport"

let url = "";
let link = window.location.href;
for (let t = link.length - 1; t > 0; --t) {
  if (link[t] !== '/') url += link[t];
  if (link[t] === '/') { url += link[t]; break; }
}

let originalUrl = "";
for (let t = url.length - 1; t >= 0; --t) {
  originalUrl += url[t];
}

const App = () => {
  const [menu, setMenu] = React.useState(false)
  const { width } = useViewPort()
  const breakpoints = 810
  const user = localStorage.getItem("us");
  const [isInLogin, setIn] = React.useState(user ? true : false);

  const handleMenuShow = () => {
    setMenu((menu) => !menu)
  }

  const pageController = () => {
    switch (originalUrl) {
      case "/change":
        return <Change />
      case "/home":
        return <Home setIn={setIn} />
      case "/policy":
        return <Policy setIn={setIn} />
      case "/profile":
        if (!user) return <Home setIn={setIn} />
        return <Profile setIn={setIn} />
      default:
        return <Home setIn={setIn}/>
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <NavBar setIn={setIn} isInLogin={isInLogin} user={user} menu={menu} handleMenuShow={handleMenuShow} setMenu={setMenu} />
      {originalUrl === "/profile" ? <hr style={{ width: "100%", backgroundColor: "#BCBFC4" }}></hr> : ""}
      <main className="main-page">
        {menu && width < breakpoints ? <MobileNav user={user} menu={menu} /> : ""}
        {originalUrl === "/" ? pageController() : pageController()}
      </main>
    </ThemeProvider>
  )
}

export default App

import styled from "styled-components"

export const FooterArea = styled.div`
  width: 100%;
  padding-top: 4.375rem;
  padding-bottom: 3.25rem;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  background-color: ${({ theme }) => theme.colors.lighter};

  @media (max-width: 850px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .footer-container {
    max-width: 68rem;
    margin: auto;
    display: block;
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    padding-top: 1.45rem;
    @media (max-width: 810px) {
      border-top: unset;
    }
  }

  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    color: ${({ theme }) => theme.colors.contactMute};

    @media (max-width: 810px) {
      grid-template-columns: 1fr;
    }

    p {
      margin: 0;
    }
  }
`

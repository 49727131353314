import styled from "styled-components"
import image from "../../assets/images/image1.png"
export const Showcase = styled.div`
  padding: 0 2.75rem;
  height: 49rem;
  display: block;
  width: 100%;

  @media (max-width: 850px) {
    padding: 0 1.25rem;
  }
  @media (max-width: 810px) {
    height: initial;
  }
  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }
`
export const ShowcaseImg = styled.div`
  background-image: url(${image});
  height: 100%;
  background-position: center center;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.25rem;
  background-attachment: scroll;
  position: relative;
  width: 100%;
  display: block;

  @media (max-width: 810px) {
    display: block;
    background-image: none;
    height: 100%;
    width: 100%;
    background-position: unset;
    opacity: unset;
    background-repeat: unset;
    background-size: unset;
    border-radius: unset;
    background-attachment: unset;
    position: relative;
  }
`

export const ShowcaseConvert = styled.div`
  ${'' /* background-image: url(${image}); */}
  height: 100%;
  background-position: center center;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.25rem;
  background-attachment: scroll;
  position: relative;
  width: 100%;
  display: block;

  @media (max-width: 810px) {
    display: block;
    background-image: none;
    height: 100%;
    width: 100%;
    background-position: unset;
    opacity: unset;
    background-repeat: unset;
    background-size: unset;
    border-radius: unset;
    background-attachment: unset;
    position: relative;
  }
`

export const DarkOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;

  @media (max-width: 810px) {
    background: transparent;
    position: static;
    top: unset;
    left: unset;
    right: unset;
    height: auto;
    border-radius: unset;
  }

  .showcase-container {
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media (max-width: 810px) {
      display: block;
      width: 100%;
      padding-top: 2.5rem;
      height: unset;
    }
  }
  .showcase-container-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 85rem;
    padding: 0 3rem;
    margin: 0 auto;
    grid-gap: 7rem;
    place-items: center;
    align-items: start;
    @media (max-width: 810px) {
      display: block;
      width: 100%;
      padding: 0;
      grid-gap: unset;
    }
  }
  .showcase-left-grid {
    width: 80%;
    @media (max-width: 810px) {
      margin-bottom: 3.125rem;
      width: 100%;
    }
  }
  .showcase-right-grid {
    display: block;
    width: 80%;
    @media (max-width: 810px) {
      width: 100%;
    }
  }
  .showcase-grid-title {
    font-size: clamp(2.4rem, 2.5vw, 4rem);
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1.75rem;
    margin-top: 0;
    line-height: 89%;
    font-weight: 500;
    @media (max-width: 810px) {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .showcase-grid-text {
    color: #767C86;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    font-weight: 300;
    @media (max-width: 810px) {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .pushinp {
    display: block;
    width: 100%;
  }
  .otdaete {
    color: #1E2839;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
  .select-form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
  }
  .input-form-control {
    width: 100%;
    padding: 10px;
  }
  .form-group {
    margin-bottom: 3rem;
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
`

export const DarkOverlayConvert = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  padding:2%;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;

  @media (max-width: 810px) {
    background: transparent;
    position: static;
    top: unset;
    left: unset;
    right: unset;
    height: auto;
    border-radius: unset;
  }

  .showcase-container {
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80%;
    width:100%;
    marginLeft:10%;
    marginTop:10%;
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 810px) {
      display: block;
      width: 100%;
      height: unset;
    }
  }
  .showcase-container-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 85rem;
    padding: 0 3rem;
    margin: 0 auto;
    grid-gap: 7rem;
    place-items: center;
    align-items: start;
    @media (max-width: 810px) {
      display: block;
      width: 100%;
      padding: 0;
      grid-gap: unset;
    }
  }
  .showcase-left-grid {
    width: 80%;
    @media (max-width: 810px) {
      margin-bottom: 3.125rem;
      width: 100%;
    }
  }
  .showcase-right-grid {
    display: block;
    width: 80%;
    @media (max-width: 810px) {
      width: 100%;
    }
  }
  .showcase-grid-title {
    font-size: clamp(2.4rem, 2.5vw, 4rem);
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1.75rem;
    margin-top: 0;
    line-height: 89%;
    font-weight: 500;
    @media (max-width: 810px) {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .showcase-grid-text {
    color: #fff;
    font-size: clamp(1.125rem, 2.5vw, 1.25rem);
    font-weight: 300;
    @media (max-width: 810px) {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .pushinp {
    display: block;
    width: 100%;
  }
  .select-form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
  }
  .input-form-control {
    width: 100%;
    padding: 10px;
  }
  .form-group {
    margin-bottom: 3rem;
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
`

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 810px) {
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
  }

  .showcase-top-title {
    font-size: clamp(1.7rem, 2.5vw, 2.75rem);
    line-height: 110%;
    font-weight: 500;
    width: 100%;
  }
  .showcase-top-text {
    font-size: clamp(1rem, 2.5vw, 1.125rem);
    font-weight: 300;
    width: 100%;
  }
`
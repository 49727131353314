import React from 'react';
import { Modal } from 'antd';
import { Table } from 'antd';
import API from "../../query";
import { useViewPort } from "../../hooks/Viewport"

function History(props) {
    const { width } = useViewPort()
    const breakpoints = 810
    const { isHistoryModalVisible, setHistoryModalVisible, data } = props

    const handleCancel = () => {
        setHistoryModalVisible(false);
    };

    return (
        <Modal title="История операций" width={600} visible={isHistoryModalVisible} onCancel={handleCancel} footer={""}>
            <form style={{ marginLeft: width < breakpoints ? "-4vw" : "" }}>
                {data && data.length ?
                    <App data={data}></App>
                    : "Вы еще не производили ни одной операции"}
            </form>
        </Modal>
    );
}

const columns = [
    {
        title: 'Дата',
        dataIndex: 'createdAt',
        // width: '20%',
    },
    {
        title: 'Сумма',
        dataIndex: 'amount',
        // width: '5%',
    },
    {
        title: 'Адрес',
        dataIndex: 'address',
        // width: '40%',
    },
    {
        title: 'Тип операции',
        dataIndex: 'actionBy',
        // width: '5%',
    },
];

class App extends React.Component {
    state = {
        data: [],
        // pagination: {
        //     current: 1,
        //     pageSize: 10,
        // },
        loading: false,
    };

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    fetch = async () => {
        this.setState({ loading: true });
        const response = await fetch(`${API.getUrl()}/user/invoices`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: JSON.parse(localStorage.getItem("xyz"))
            },
            body: JSON.stringify({ isAdminInvoice: false, limit: 50, skip: 0, userId: JSON.parse(localStorage.getItem("us")).id }),
        });

        const { invoices, result } = await response.json();
        if (!result) {
            this.setState({
                loading: false,
            })
        }
        invoices.forEach(e => {
            const date = new Date(e.createdAt);
            e.actionBy = e.actionType == "1" ? "Банковская карта" : e.actionType == "2" ? "Coin" : e.actionType == "3" ? "Банк" : "Пополнение "
            e.createdAt = date.getFullYear() + "-" + ((date.getMonth() + 1) <= 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate());
        });
        this.setState({
            loading: false,
            data: invoices
        })
    };

    render() {
        const { data, pagination, loading } = this.state;
        return (
            <Table
            scroll={{ x: 400 }}

                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
            />
        );
    }
}

export default History;
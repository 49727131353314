import React from "react"
import {
  MobileNavigation,
  MobileNavContainer,
} from "../layouts/layoutStyles/mobileNavStyles"

const MobileNav = ({ menu }) => {
  React.useEffect(() => {
    let offset = 0

    if (menu) {
      offset = window.scrollY
      document.body.setAttribute(
        "style",
        `position: fixed; top: -${offset}px; left: 0; right: 0;`
      )
    }
    return () => {
      document.body.setAttribute("style", "")
      window.scrollTo(0, offset)
    }
  }, [menu])

  return (
    <MobileNavigation>
      <MobileNavContainer>
        <div style={{height:"19vh", fontSize:"15px"}} className="mobile-nav-box" onClick={() => window.location.href = "/home"}>Обменять валюту</div>
        <div style={{height:"19vh", fontSize:"15px"}} className="mobile-nav-box" onClick={() => window.location.href = "/profile"}>Профиль</div>
        <div style={{height:"19vh", fontSize:"15px"}} className="mobile-nav-box" onClick={() => window.location.href = "/policy"}>Правила сайта</div>
        <div style={{height:"19vh", fontSize:"15px"}} className="mobile-nav-box">FAQ</div>
        <div style={{height:"19vh", fontSize:"15px"}} className="mobile-nav-box">Обратная связь</div>
      </MobileNavContainer>
    </MobileNavigation>
  )
}

export default MobileNav

import API from "../query";

class Profile {
    async updateLocalData() {
        try {
            const user = JSON.parse(localStorage.getItem("us"));
            const { error, data } = await API.login(null, null, user.id);

            if (error) {
                console.log(error);
            }

            let itemToSave = {
                paymentType: data.payment ? data.payment.paymentType : "",
                method3Text: data.payment ? data.payment.method3Text : "",
                email: data.user.email,
                firstName: data.user.firstName,
                id: data.user.id,
                isOnline: data.user.isOnline,
                lastName: data.user.lastName,
                money: data.user.money,
                paymentActivation: data.user.paymentActivation,
                paymentSystemId: data.payment ? data.payment.paymentSystemId : "",
                phone: data.user.phone,
                surName: data.user.surName,
                isDownloadP: data.user.passportId ? true : false
            }
            console.log(itemToSave)
            localStorage.setItem("us", JSON.stringify(itemToSave));
            localStorage.setItem("uscr", JSON.stringify(data.cryptos));
            localStorage.setItem("xyz", JSON.stringify(data.authToken));
            localStorage.setItem("exp", JSON.stringify(data.tokenExpiresAfter));
        } catch (error) {
            console.log("Error on login():", error);
        }
    }

    getUserCoins() {
        let userCoins = JSON.parse(localStorage.getItem("uscr"));
        let user = JSON.parse(localStorage.getItem("us"));
        userCoins.paymentSystemId = user.paymentSystemId;

        return userCoins;
    };

    getTotalBalance() {
        let userCoins = JSON.parse(localStorage.getItem("uscr")), total = 0;
        userCoins.forEach(c=>{
            total+=(c.quantity*Number(c.price_usd))
        })
        return total;
    };

    getLocalUserData() {
        let user = JSON.parse(localStorage.getItem("us"));
        return user;
    };

    logOut(setIn) {
        setIn(false);
        localStorage.removeItem("us");
        localStorage.removeItem("prus");
        localStorage.removeItem("uscr");
        localStorage.removeItem("xyz");
        window.location.href = "/home";
    };

    async withDrawMoney(dataObject, withdrawType) {
        const user = JSON.parse(localStorage.getItem("us"));
        let totalAmount = user.money;
        const nowDate = new Date();
        if (withdrawType == 1) {
            const { cardOwner, cardNumber, cardYear, cardMonth, amountToTransfer } = dataObject;
            if (!cardOwner || !cardNumber || !cardYear || cardMonth < 0 || cardMonth > 12) {
                return { error: "Любой из файлов может быть неправильным или пустым." };
            }
            if (cardYear < nowDate.getFullYear()%100) {
                return { error: "Год карты неверный" };
            }
            if (cardMonth < 1 && cardMonth > 12) {
                return { error: "Неверный месяц карты" };
            }
            if (amountToTransfer > totalAmount) {
                return { error: "Сумма не может быть больше, чем у вас есть" };
            }

            const { result, error } = await API.withDrawMoneyType1(amountToTransfer, cardNumber, withdrawType);
            return { result, error };
        } else if (withdrawType == 2) {
            const { bitcoinAddress, bitcoinBalance } = dataObject;
            if (!bitcoinAddress || !bitcoinBalance) {
                return { error: "Поля могут быть неправильными." };
            }
            const { error } = await API.withDrawMoneyType2(bitcoinAddress, bitcoinBalance, withdrawType);
            return { error };
        } else if (withdrawType == 3) {
            const { INN, KPP, RC, KORC, BIK, amountToTransfer } = dataObject;
            if (!amountToTransfer || !INN || !KPP || !RC || !KORC || !BIK) {
                return { error: "Все поля обязательны для заполнения." };
            }
            if (amountToTransfer > totalAmount) {
                return { error: "Сумма не может быть больше, чем у вас есть" };
            }
            const { error } = await API.withDrawMoneyType3(INN, KPP, RC, KORC, BIK, amountToTransfer, withdrawType);
            return { error };
        }
    };

    async userInvoices() {
        try {
            const { invoices, result } = await API.userInvoices();
            return { error: !result ? "Something went wrong." : null, data: invoices };
        } catch (error) {
            return { error: error.message, data: null };
        }
    }
}
const profile = new Profile();
setInterval(async () => {
    await profile.updateLocalData();
}, 8000);
export default profile;

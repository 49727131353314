import styled from "styled-components"

export const SmNav = styled.nav`
  width: 60%;
  margin-left: 18.4vw;
  margin-right: 18.4vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: "16px";

  @media (max-width: 850px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @media (max-width: 810px) {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  }
  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .nav-container {
    max-width: 68rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      .middle-nav-list > * + * {
        margin: 0 0 0 1.3rem;
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    cursor: pointer;

    @media (min-width: 810px) {
      & * + * {
        margin: 0 0 0 0.813rem;
      }
    }
    & * + * {
      margin: 0 0 0 0.413rem;
    }

    .logo-brand {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.725rem;
      color: ${({ theme }) => theme.colors.csblue};
    }
  }

  .middle-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;

    & * + * {
      margin: 0 0 0 2.5rem;
    }

    @media (max-width: 810px) {
      display: none;
    }
  }
  .end-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;

    & * + * {
      margin: 0 0 0 1rem;
    }
    @media (max-width: 810px) {
      flex-direction: row-reverse;
    }
  }

  .user-circle-icon {
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    padding: 0.6rem;
    cursor: pointer;
  }

  .user-no-circle {
    border: 1px solid ${({ theme }) => theme.colors.csblue};
  }

  .end-nav-list-mobile {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;
    & * + * {
      margin: 0 0 0 0.413rem;
    }
    @media (min-width: 810px) {
      & * + * {
        margin: 0 0 0 0.413rem;
      }
    }

    .menu-btn {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .btn-active {
      background-color: ${({ theme }) => theme.colors.csblue};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`


export const SmNavMobile = styled.nav`

  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  @media (max-width: 850px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @media (max-width: 810px) {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  }
  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .nav-container {
    max-width: 68rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      .middle-nav-list > * + * {
        margin: 0 0 0 1.3rem;
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    cursor: pointer;

    @media (min-width: 810px) {
      & * + * {
        margin: 0 0 0 0.813rem;
      }
    }
    & * + * {
      margin: 0 0 0 0.413rem;
    }

    .logo-brand {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.725rem;
      color: ${({ theme }) => theme.colors.csblue};
    }
  }

  .middle-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;

    & * + * {
      margin: 0 0 0 2.5rem;
    }

    @media (max-width: 810px) {
      display: none;
    }
  }
  .end-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;

    & * + * {
      margin: 0 0 0 1rem;
    }
    @media (max-width: 810px) {
      flex-direction: row-reverse;
    }
  }

  .user-circle-icon {
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    padding: 0.6rem;
    cursor: pointer;
  }

  .user-no-circle {
    border: 1px solid ${({ theme }) => theme.colors.csblue};
  }

  .end-nav-list-mobile {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;
    & * + * {
      margin: 0 0 0 0.413rem;
    }
    @media (min-width: 810px) {
      & * + * {
        margin: 0 0 0 0.413rem;
      }
    }

    .menu-btn {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .btn-active {
      background-color: ${({ theme }) => theme.colors.csblue};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`


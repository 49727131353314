import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import RegisterModal from "./registerModal";
import LoginModel from "../../models/loginModel";

function Login(props) {
    const { isModalVisible, setIsModalVisible } = props
    const [isRegisterVisible, setRegisterModalVisible] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState(null);
    const [errorMessage, setError] = useState(null);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showRegisterModal = () => {
        setRegisterModalVisible(true);
        setIsModalVisible(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const login = async () => {
        const { error } = await LoginModel.login(email, password);
        if (error) return setError(error);
    }

    return (
        <>
            <RegisterModal isRegisterVisible={isRegisterVisible} setRegisterModalVisible={setRegisterModalVisible} setIsModalVisible={setIsModalVisible} />
            <Modal title="Войдите или зарегистрируйтесь" visible={isModalVisible} onCancel={handleCancel} footer={""}>
                <form style={{ textAlign: "center" }}>
                    <div className="form-group">
                        <Input
                            size="large"
                            type="email"
                            onChange={handleEmailChange}
                            placeholder="Введите e-mail"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                fontWeight:"300",
                                fontSize:"16",
                                color: "#767C86",
                            }}
                        />
                    </div>
                    <div>
                        <Input
                            size="large"
                            type="password"
                            onChange={handlePasswordChange}
                            placeholder="Введите пароль"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                fontWeight:"300",
                                fontSize:"16",
                                background: "transparent",
                                color: "#767C86",
                            }}
                        />
                    </div>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <Button
                        size="large"
                        onClick={login}
                        style={{
                            display: "inherit",
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "1.75rem",
                            color: "white",
                            fontSize:"13px",
                            fontWeight:"400",
                            padding: "0 2rem",
                            backgroundColor: "#035AFC",
                        }}
                        className="sm-block"
                    >
                        Войти в профиль
                    </Button><br></br>
                    <div style={{ width: "266px", marginLeft: "7vw", color:"#767C86" }}>
                        Впервые на этом сайте?
                        <a
                            href="#!"
                            onClick={showRegisterModal}
                            style={{
                                textDecoration: "underline",
                                color: "blue",
                                fontWeight: "300",
                                left: "317px",
                                top: "354px",
                            }}
                        >
                            {" "}Создайте новый аккаунт
                        </a>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default Login;
import styled from "styled-components"

export const CoinListing = styled.div`
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
  padding: 0 2.75rem;
  display: block;
  width: 100%;

  @media (max-width: 850px) {
    padding: 0 1.25rem;
  }

  @media (max-width: 810px) {
    margin-top: 5rem;
  }

  @media (max-width: 325px) {
    padding-left: 0.69rem;
    padding-right: 0.69rem;
  }

  .coin-list-container {
    max-width: 68rem;
    margin: auto;
    display: block;
    @media (max-width: 810px) {
      max-width: unset;
      width: 100%;
    }
  }
`
export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 810px) {
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
  }

  .showcase-top-title {
    font-size: clamp(2rem, 2.5vw, 2.75rem);
    line-height: 110%;
    font-weight: 500;
    width: 100%;
  }
  .showcase-top-text {
    font-size: clamp(1rem, 2.5vw, 1.125rem);
    font-weight: 300;
    width: 100%;
  }
`
export const ListSection = styled.div`
  margin-top: 3.625rem;
  display: block;
  width: 100%;

  .coin-listing-area {
    display: block;
    width: 100%;
    margin-top: 3.125rem;
    font-size: clamp(0.9rem, 2.5vw, 1.125rem);

    .coin-listing-area-item {
      border-top: 1px solid ${({ theme }) => theme.colors.grey};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;

      &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
      }

      .coin-name-img {
        display: flex;
        flex-direction: row;
        align-items: center;

        & * + * {
          margin: 0 0 0 0.413rem;
        }

        @media (min-width: 810px) {
          & * + * {
            margin: 0 0 0 1rem;
          }
        }

        .coin-listing-name {
          font-weight: 500;
        }
      }
      .coin-listing-value {
        .coin-listing-amount {
          margin: 0;
          font-weight: 300;
        }
      }
    }
  }
`
export const ListTabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 810px) {
    .list-cta-tabs > * + * {
      margin: 0 0 0 1rem;
    }
  }

  @media (max-width: 810px) {
    display: block;
  }

  .list-cta-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    @media (max-width: 810px) {
      display: grid;
      grid-gap: 1rem;

      .first-tab {
        grid-row: 1;
        grid-column: 1/2;
      }
      .end-tab {
        grid-row: 2;
        grid-column: 1/3;
      }
      .middle-tab {
        grid-row: 1;
        grid-column: 2/3;
      }
    }
  }
`

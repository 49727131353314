import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { useViewPort } from "../../hooks/Viewport"
import LoginModel from "../../models/loginModel";

function Register(props) {
    const { width } = useViewPort()
    const breakpoints = 810
    const { isRegisterVisible, setRegisterModalVisible, setIsModalVisible } = props
    const [email, setEmail] = useState();
    const [password, setPassword] = useState(null);
    const [passwordAgain, setAgainPassword] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [surName, setSurName] = useState(null);
    const [code, setVerificationCode] = useState(null);
    const [phone, setPhone] = useState(null);
    const [errorMessage, setError] = useState(null);

    const handleCancel = () => {
        setRegisterModalVisible(false);
    };
    const openLoginModal = () => {
        setRegisterModalVisible(false);
        setIsModalVisible(true);
    };
    const onClickGetCode = async () => {
        const { error } = await LoginModel.sendRegistrationCode(email);
        if(error) return setError(error);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handlePasswordAgainChange = (e) => {
        setAgainPassword(e.target.value);
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }
    const handlePhoneNumberChange = (e) => {
        setPhone(e.target.value);
    }
    const handleSurNameNameChange = (e) => {
        setSurName(e.target.value);
    }
    const handleVerificationCodeChange = (e) => {
        setVerificationCode(e.target.value);
    }
    const register = async () => {
        const { error } = await LoginModel.register(code, password, passwordAgain, firstName, lastName, surName, email, phone);
        if (error) return setError(error);
    }

    return (
        <>
            <Modal title="Войдите или зарегистрируйтесь" visible={isRegisterVisible} onCancel={handleCancel} footer={""}>
                <form >
                    <div className="form-group">
                        <Input
                            size="large"
                            onChange={handleFirstNameChange}
                            type="text"
                            placeholder="Имя"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                fontWeight:"200",
                                background: "transparent",
                                color: "black",
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            onChange={handleLastNameChange}
                            type="text"
                            placeholder="Фамилия"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            onChange={handleSurNameNameChange}
                            type="text"
                            placeholder="Отчество"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            onChange={handleEmailChange}
                            type="email"
                            placeholder="E-mail"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <div style={{ width: "20%", position: "absolute" }}>
                            {width > breakpoints ?
                                <Button onClick={onClickGetCode} style={{ borderRadius: "5px", fontWeight:"300", fontSize:"12px" }} size="large">Получить код</Button> : <Button onClick={onClickGetCode} style={{ height: "40px", borderRadius: "5px", fontWeight:"450", fontSize:"12px" }} size="small">Получить код</Button>}
                        </div>
                        <div style={{ width: "63%", marginLeft: "37%" }}><Input
                            size="large"
                            type="number"
                            onChange={handleVerificationCodeChange}
                            placeholder="Введите код"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        /></div>
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            onChange={handlePhoneNumberChange}
                            placeholder="Номер телефона"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            type="password"
                            onChange={handlePasswordChange}
                            placeholder="Пароль"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                        <p style={{
                            color: "#767C86",
                            fontWeight:"300",
                            fontSize:"12px"
                        }}>Пароль должен состоять из 8-20 символов, содержать буквы и цифры.</p>
                    </div>
                    <div className="form-group">
                        <Input
                            size="large"
                            type="password"
                            onChange={handlePasswordAgainChange}
                            placeholder="Повторите пароль"
                            className="input-form-control"
                            style={{
                                borderRadius: "5px",
                                background: "transparent",
                                color: "black",
                                fontWeight:"200",
                            }}
                        />
                    </div>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <Button
                        size="large"
                        onClick={register}
                        style={{
                            display: "inherit",
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "1.75rem",
                            color: "white",
                            padding: "0 2rem",
                            fontWeight:"300",
                            fontSize:"14px",
                            backgroundColor: "#035AFC",
                        }}
                        className="sm-block"
                    >
                        Зарегистрироваться
                    </Button><br></br>
                    <div style={{ width: "266px", marginLeft: "7vw", textAlign: "center" }}>
                        Уже есть аккаунт?
                        <a
                            href="#!"
                            onClick={openLoginModal}
                            style={{
                                textDecoration: "underline",
                                color: "blue",
                                fontWeight: "300",
                                left: "317px",
                                top: "354px",
                            }}
                        >
                            Войти
                        </a>
                    </div>
                </form>
            </Modal>
        </>

    );
}

export default Register;
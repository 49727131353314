import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import OutputMethod from '../modals/outputMethod';

function Login(props) {
    const { setVisible, visible, Profile } = props
    const [outputMethod, setOutputMethodVisible] = useState(false);
    const [userCoins] = useState(Profile.getUserCoins());
    const [selectedCoinId, setSelectedCoinId] = useState();
    const [selectedCoinName, setSelectedCoinName] = useState();

    const handleNext = () => {
        if (!selectedCoinId) return;
        setVisible(false);
        setOutputMethodVisible(true);
    };

    const onSelectCoin = (e) => {
        setSelectedCoinId(e.id);
        setSelectedCoinName(e.name)
    };

    return (
        <>
            <OutputMethod selectedCoinName={selectedCoinName} setVisible={setOutputMethodVisible} visible={outputMethod} Profile={Profile}></OutputMethod>
            <Modal width={400} centered
                title="С какого кошелька хотите вывести средства?" visible={visible} onCancel={() => setVisible(false)} footer={""}>
                <form>
                        {userCoins.map(e => {
                            return <div onClick={() => onSelectCoin(e)} style={{ top: "1vh", backgroundColor: selectedCoinId && e.id === selectedCoinId ? "gray" : "", }}>
                                <Input
                                    id={e.id}
                                    addonBefore={
                                        e.name
                                    }
                                    disabled
                                    style={{ textAlign: "right", padding: "1px", cursor: "pointer",  borderRadius: "5px" }}
                                    placeholder={userCoins.paymentSystemId}
                                    // prefix={e.name}
                                /><p></p></div>
                        })}
                    

                    <Button
                        onClick={handleNext}
                        size="large"
                        style={{
                            display: "inherit",
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "1.75rem",
                            color: "white",
                            fontWeight:"300",
                            fontSize:"13px",
                            padding: "0 2rem",
                            backgroundColor: "#035AFC",
                        }}
                        className="sm-block"
                    >
                        Дальше
                    </Button>
                </form>
            </Modal></>

    );
}

export default Login;
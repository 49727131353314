import React, { useState } from "react"
import { ContactArea, ContactFormGrid } from "./componentsStyle/contactStyle"
import { Input, Button } from "antd"
import ContactModel from "../models/contactModel"

const Contact = () => {
  const [question, setQuestion] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);

  const sendMessage = async () => {
    const { error } = await ContactModel.sendEmailToOwner(email, name, question);
    if (error) setError(error);
  }

  return (
    <ContactArea>
      <div className="contact-container">
        <ContactFormGrid>
          <div className="contact-form-left">
            <h3 className="contact-form-title">Навигация</h3>
            <ul className="contact-form-list">
              <li className="contact-form-list-item">Обменять валюту</li>
              <li className="contact-form-list-item">Личный кабинет</li>
            </ul>
          </div>
          <div className="contact-form-middle">
            <h3 className="contact-form-title">Поддержка</h3>
            <ul className="contact-form-list">
              <li className="contact-form-list-item">Правила сайта</li>
              <li className="contact-form-list-item">Предупреждение</li>
            </ul>
          </div>
          <div className="contact-form-right">
            <h3 className="contact-form-title">Обратная связь</h3>
            <form>
              <div className="form-group">
                <label
                  htmlFor="country"
                  style={{
                    fontSize: "14px",
                    color: "#767C86",
                    fontWeight: "300",
                  }}
                >
                  Ваш вопрос
                </label>
                <Input
                  size="small"
                  type="text"
                  onChange={(e) => setQuestion(e.target.value)}
                  className="input-form-control"
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    background: "transparent",
                    color: "#767C86",
                  }}
                />
              </div>
              <div className="form-group form-group-with-column">
                <div className="left-column">
                  <label
                    htmlFor="country"
                    style={{
                      fontSize: "14px",
                      color: "#767C86",
                      fontWeight: "300",
                    }}
                  >
                    Имя
                  </label>
                  <Input
                    size="small"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Александр"
                    className="input-form-control"
                    style={{
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      background: "transparent",
                      color: "#767C86",
                      padding:"0"
                    }}
                  />
                </div>
                <div className="right-column">
                  <label
                    htmlFor="country"
                    style={{
                      fontSize: "14px",
                      color: "#767C86",
                      fontWeight: "300",
                    }}
                  >
                    E-mail
                  </label>
                  <Input
                    size="small"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@mail.ru"
                    className="input-form-control"
                    style={{
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      background: "transparent",
                      color: "#767C86",
                      padding:"0"
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  color: "black",
                  fontWeight: "350",
                  margin: "1.875rem 0 0 0",
                  fontSize: "clamp(0.89rem, 2vw, 1rem)",
                }}
              >
                Нажимая кнопку “Отправить” вы даете согласие на 
                <a
                href="#!"
                style={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: "350",
                  fontSize: "clamp(0.89rem, 2vw, 1rem)",
                }}
              >
                 {" "}обработку персональных данных
              </a>
              </p>
              
              <p style={{ color: "red" }}>{error}</p>
              <Button
                size="large"
                onClick={sendMessage}
                style={{
                  display: "block",
                  borderRadius: "5px",
                  fontWeight: "300",
                  marginTop: "1.75rem",
                  backgroundColor: "transparent",
                  padding: "0 2rem",
                }}
                className="sm-block"
              >
                Отправить
              </Button>
            </form>
          </div>
        </ContactFormGrid>
      </div>
    </ContactArea>
  )
}

export default Contact

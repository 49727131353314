import React from "react"
import CoinList from "../../components/CoinLists"
import Contact from "../../components/Contact"
import Footer from "../../components/Footer"

const Change = () => {
  return (
    <>
      {/* <ShowCase /> */}
      <CoinList />
      <Contact />
      <Footer />
    </>
  )
}

export default Change

import React, { useState } from 'react';
import { Modal, Button, Input, Tabs, Row, Col } from 'antd';
import InfoModal from '../modals/infoModal';
import { useViewPort } from "../../hooks/Viewport"

const { TabPane } = Tabs;

function Login(props) {
    const { width } = useViewPort()
    const breakpoints = 810
    const { setVisible, visible, Profile, selectedCoinName } = props
    // withdraw type 1 data
    const [cardOwner, setCardOwner] = useState();
    const [cardNumber, setCardNumber] = useState();
    const [cardYear, setCardYear] = useState();
    const [cardMonth, setCardMonth] = useState();
    const [cardCvc, setCardCvc] = useState();
    const [typeOfWithdraw, setType] = useState(1);
    const [isInfoModalVisible, setInfoModalVisible] = useState(false);
    const [amountToTransfer, setAmountToTransfer] = useState();
    // withdraw type 2 data
    const [bitcoinAddress, setBitcoinAddress] = useState();
    const [bitcoinBalance, setBitcoinBalance] = useState();
    // withdraw type 3 data
    const [INN, setINN] = useState();
    const [KPP, setKPP] = useState();
    const [RC, setRC] = useState();
    const [KORC, setKORC] = useState();
    const [BIK, setBIK] = useState();

    const [modalHeader, setHeaderText] = useState("Выберите платежную систему и укажите реквизиты для вывода");
    const user = JSON.parse(localStorage.getItem("us"));

    const [infoText1, setText] = useState(user.paymentType == 3 ? user.method3Text : "Ожидайте зачисления средств по указанным вами реквизитам.");
    const [errorMessage, setError] = useState();

    const handleCancel = () => {
        setVisible(false);
    };

    const onWithdraw = async () => {
        const { error } = await Profile.withDrawMoney({
            cardOwner, cardNumber, cardYear, cardMonth, cardCvc, amountToTransfer, // type 1
            bitcoinAddress, bitcoinBalance, //type 2
            INN, KPP, RC, KORC, BIK // type 3
        }, typeOfWithdraw);
        if (error) return setError(error);
        setVisible(false);
        setInfoModalVisible(true);
    };

    return (
        <>
            <InfoModal isInfoModalVisible={isInfoModalVisible} fromCreatingInvoice={true} setInfoModalVisible={setInfoModalVisible} infoHeader={"Заявка создана"} infoText1={infoText1}></InfoModal>
            <Modal width={500} centered
                title={modalHeader} visible={visible} onCancel={handleCancel} footer={""}>
                <form>
                    <Tabs onTabClick={(e) => setType(e)} size='large' type='card' defaultActiveKey="1" centered>
                        <TabPane tab="Банковская карта" key="1">
                            <Row>
                                <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                    Владелец карты
                                </label>
                                <Input
                                    size="large"
                                    type="text"
                                    onChange={(e) => setCardOwner(e.target.value)}
                                    placeholder=""
                                    className="input-form-control"
                                    style={{
                                        border: "1px solid #BCBFC4",
                                        boxSizing: "border-box",
                                        borderRadius: "5px",
                                        marginTop: "-2.5vh",
                                        background: "transparent",
                                        color: "#767C86",
                                    }}
                                />
                            </Row><p></p>
                            <Row>
                                <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                    Номер карты
                                </label>
                                <Input
                                    size="large"
                                    type="number"
                                    onChange={(e) => setCardNumber(e.target.value)}
                                    placeholder="**** **** **** ****"
                                    className="input-form-control"
                                    style={{
                                        border: "1px solid #BCBFC4",
                                        boxSizing: "border-box",
                                        borderRadius: "5px",
                                        marginTop: "-2.5vh",
                                        background: "transparent",
                                        color: "#767C86",
                                    }}
                                />
                            </Row><p></p>
                            <Row>
                                <label htmlFor="country" style={{
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    fontSize: "16px",
                                    lineHeight: "125%",
                                    color: "#1E2839"
                                }}>
                                    Сумма к выводу
                                </label>
                                <Input
                                    size="large"
                                    type="number"
                                    placeholder='Сумма к выводу'
                                    onChange={(e) => setAmountToTransfer(e.target.value)}
                                    className="input-form-control"
                                    style={{
                                        border: "1px solid #BCBFC4",
                                        boxSizing: "border-box",
                                        borderRadius: "5px",
                                        marginTop: "-2.5vh",
                                        background: "transparent",
                                        color: "#767C86",
                                    }}
                                />
                            </Row><p></p>
                            <Row>
                                <Col span={19}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        Срок действия
                                    </label>
                                </Col>
                                <Col>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        Код
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <Input
                                        type="number"
                                        onChange={(e) => setCardMonth(e.target.value)}
                                        placeholder="ММ"
                                        max={12}
                                        min={1}
                                        className="input-form-control"
                                        style={{
                                            width: width < breakpoints ? "13vw" : "4.8vw",
                                            height: "5.4vh",
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-3vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col><Col span={1}></Col><Col xs={1}  > / </Col>
                                <Col span={14}>
                                    <Input
                                        type="number"
                                        onChange={(e) => setCardYear(e.target.value)}
                                        placeholder="ГГ"
                                        className="input-form-control"
                                        min={2022}
                                        style={{
                                            width: width < breakpoints ? "13vw" : "11.6vh",
                                            height: "5.4vh",
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-3vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                                <Col span={3}>
                                    <Input
                                        type="number"
                                        onChange={(e) => setCardCvc(e.target.value)}
                                        placeholder="CVC"
                                        className="input-form-control"
                                        style={{
                                            width: width < breakpoints ? "17vw" : "6.5vw",
                                            height: "5.4vh",
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-3vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <p style={{ color: "red" }}>{errorMessage}</p>
                            </Row>
                        </TabPane>
                        <TabPane tab={width < breakpoints ? selectedCoinName ? selectedCoinName : "BTC кошелек" : selectedCoinName ? selectedCoinName : "Биткоин кошелек"} key="2">
                            <Row>
                                <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                    Номер кошелька
                                </label>
                                <Input
                                    size="large"
                                    type="text"
                                    onChange={(e) => setBitcoinAddress(e.target.value)}
                                    className="input-form-control"
                                    style={{
                                        border: "1px solid #BCBFC4",
                                        boxSizing: "border-box",
                                        borderRadius: "5px",
                                        marginTop: "-2.5vh",
                                        background: "transparent",
                                        color: "#767C86",
                                    }}
                                />
                            </Row><p></p>
                            <Row>
                                <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                    Cумма
                                </label>
                                <Input
                                    size="large"
                                    type="number"
                                    onChange={(e) => setBitcoinBalance(e.target.value)}
                                    className="input-form-control"
                                    style={{
                                        border: "1px solid #BCBFC4",
                                        boxSizing: "border-box",
                                        borderRadius: "5px",
                                        marginTop: "-2.5vh",
                                        background: "transparent",
                                        color: "#767C86",
                                    }}
                                />
                            </Row><p></p>
                            <Row>
                                <p style={{ color: "red" }}>{errorMessage}</p>
                            </Row>
                        </TabPane>
                        <TabPane tab="Банк" key="3">
                            <Row>
                                <Col span={11}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        ИНН
                                    </label>
                                    <Input
                                        size="large"
                                        type="text"
                                        placeholder='ИНН'
                                        onChange={(e) => setINN(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-2.5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        КПП
                                    </label>
                                    <Input
                                        size="large"
                                        type="text"
                                        placeholder='КПП'
                                        onChange={(e) => setKPP(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-2.5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                            </Row><p></p>
                            <Row>
                                <Col span={11}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        Р/C
                                    </label>
                                    <Input
                                        size="large"
                                        type="text"
                                        placeholder='Р/C'
                                        onChange={(e) => setRC(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-2.5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        Кор/c
                                    </label>
                                    <Input
                                        size="large"
                                        type="text"
                                        placeholder='Кор/c'
                                        onChange={(e) => setKORC(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-2.5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                            </Row><p></p>
                            <Row>
                                <Col span={11}>
                                    <label htmlFor="country" style={{ fontSize: "16px", fontWeight: "300" }}>
                                        БИК
                                    </label>
                                    <Input
                                        size="large"
                                        placeholder='БИК'
                                        type="text"
                                        onChange={(e) => setBIK(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-2.5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <label htmlFor="country" style={{
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        fontSize: "16px",
                                        lineHeight: "125%",
                                        color: "#1E2839",
                                        paddingBottom:"0.9vh",
                                    }}>
                                        Сумма к выводу
                                    </label>
                                    <Input
                                        size="large"
                                        type="number"
                                        placeholder='Сумма к выводу'
                                        onChange={(e) => setAmountToTransfer(e.target.value)}
                                        className="input-form-control"
                                        style={{
                                            border: "1px solid #BCBFC4",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            marginTop: "-5vh",
                                            background: "transparent",
                                            color: "#767C86",
                                        }}
                                    />
                                </Col>
                            </Row><p></p>
                            <Row>
                                <p style={{ color: "red" }}>{errorMessage}</p>
                            </Row>
                        </TabPane>
                    </Tabs>
                    <Button
                        onClick={onWithdraw}
                        size="large"
                        style={{
                            display: "inherit",
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "1.75rem",
                            fontWeight: "300",
                            fontSize: "14px",
                            color: "white",
                            padding: "0 2rem",
                            backgroundColor: "#035AFC",
                        }}
                        className="sm-block"
                    >
                        Вывести средства
                    </Button>
                </form>
            </Modal></>

    );
}

export default Login;

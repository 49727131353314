import React, { useState } from "react"
import ShowcaseTop from "./ShowcaseTop"
import { Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
import {
  CoinListing,
  ListSection,
  ListTabs,
} from "./componentsStyle/coinListStyling"
import LoginModel from "../models/loginModel";
import { Audio } from 'react-loader-spinner'

function CoinList() {
  const [coins, setCoins] = useState();
  const [isStart, setStart] = useState(0);
  const [list, setList] = useState(2);

  async function startingData() {
    LoginModel.loadDataIntoTable(setCoins);
    setStart(1);
  }
  if (isStart === 0) startingData();

  return (
    <CoinListing>
      <div className="coin-list-container">
        <ShowcaseTop />
        <ListSection>
          <ListTabs>
            <ul className="list-cta-tabs">
              <li className="first-tab">
                <Button
                  type={list == 1 ? "primary" : ""}
                  size="large"
                  onClick={() => setList(1)}
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    fontWeight: "400",
                    backgroundColor: list == 1 ? "#035AFC" : "",
                    fontSize: "14px"//"clamp(0.8rem, 2.5vw, 1rem)",
                  }}
                >
                  Банки
                </Button>
              </li>
              <li className="middle-tab">
                <Button
                  type={list == 2 ? "primary" : ""}
                  size="large"
                  onClick={() => setList(2)}
                  style={{
                    width: "100%",
                    backgroundColor: list == 2 ? "#035AFC" : "",
                    borderRadius: "20px",
                    fontWeight: "400",
                  }}
                >
                  Coin
                </Button>
              </li>
              <li className="end-tab">
                <Button
                  size="large"
                  onClick={() => setList(3)}
                  style={{
                    width: "100%",
                    backgroundColor: list == 3 ? "#035AFC" : "",
                    borderRadius: "20px",
                    fontWeight: "400",
                  }}
                >
                  Платежные системы
                </Button>
              </li>
            </ul>
          </ListTabs>
          <ReloadOutlined onClick={() => {
            setCoins(null)
            LoginModel.loadDataIntoTable(setCoins)
          }} />
          {list == 1 ?
            <ul className="coin-listing-area">
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">Gazprombank </p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://www.gazprombank.ru/" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">VTB Bank</p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://www.vtb.ru/" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">Alfa Bank</p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://alfabank.ru/" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">Сбербанк</p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://www.sberbank.com/ru" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">Россельхозбанк</p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://www.rshb.ru/" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                    {/* <img src={coin.image_url} alt="coin logo" /> */}
                  </div>
                  <p className="coin-listing-name">Промсвязьбанк</p>
                </div>
                <div className="coin-listing-value">
                  <a href="https://www.psbank.ru/" className="coin-listing-amount">
                    Official Website
                  </a>
                </div>
              </li>
            </ul>
            : ""
          }
          {list == 2 ?
            <ul className="coin-listing-area">
              {coins
                ? coins.map((coin) => (
                  <li className="coin-listing-area-item" key={coin.id}>
                    <div className="coin-name-img">
                      <div className="coin-img-container">
                        {/* <img src={coin.image_url} alt="coin logo" /> */}
                      </div>
                      <p className="coin-listing-name">{coin.name}</p>
                    </div>
                    <div className="coin-listing-value">
                      <p className="coin-listing-amount">
                        {coin.amount} $
                      </p>
                    </div>
                  </li>
                )) : <Audio
                  heigth="100"
                  width="100"
                  color='grey'
                  ariaLabel='loading'
                />
              }
            </ul> : ""}
          {list == 3 ?
            <ul className="coin-listing-area">
              <li className="coin-listing-area-item" key={0}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">China UnionPay</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
              <li className="coin-listing-area-item" key={1}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">VISA International Service Association</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
              <li className="coin-listing-area-item" key={2}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">Mastercard</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
              <li className="coin-listing-area-item" key={3}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">JCB</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
              <li className="coin-listing-area-item" key={4}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">American Express</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
              <li className="coin-listing-area-item" key={5}>
                <div className="coin-name-img">
                  <div className="coin-img-container">
                  </div>
                  <p className="coin-listing-name">Diners Club International.</p>
                </div>
                <div className="coin-listing-value">

                </div>
              </li>
            </ul>
            : ""}
        </ListSection>
      </div>
    </CoinListing >
  )
}

export default CoinList

import React from "react"
// import ShowCase from "../../components/ShowCase"
import Contact from "../../components/Contact"
import Footer from "../../components/Footer"
import Body from "../../components/ProfileBody"

const Profile = ({ setIn }) => {
    return (
        <>
            {/* <ShowCase /> */}
            <Body setIn={setIn} />
            <Contact />
            <Footer />
        </>
    )
}

export default Profile


import styled, { css } from "styled-components"
import { Row, Col, Button } from "antd"
import { useViewPort } from "../hooks/Viewport"
import { useState } from "react";
import SelectCrypto from "./modals/selectCrypto"
import Profile from "../models/profileModel"
import InfoModal from "./modals/infoModal";
import HistoryModal from "./modals/historyModal";
import Notification from "./modals/notificationModal";
import { ReloadOutlined } from "@ant-design/icons"

function App({ setIn }) {
  const { width } = useViewPort()
  const breakpoints = 810
  const [balanceInfoGrid] = useState({
    f1: 1,
    f2: 22,
    f3: 1,
  });
  const [thisUser] = useState(Profile.getLocalUserData());
  const [visible, setVisible] = useState(false);
  const [isInfoModalVisible, setInfoModalVisible] = useState(false);
  const [isHistoryModalVisible, setHistoryModalVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [userCoins, setCoins] = useState(Profile.getUserCoins());

  const [totalBalance, setBalance] = useState(Profile.getTotalBalance())

  const handleLogOut = () => {
    Profile.logOut(setIn);
  }

  const openWithdrawModal = () => {
    setVisible(true);
    setBalance(Profile.getTotalBalance());
    setCoins(Profile.getUserCoins())
  }

  const openInvoiceHistory = async () => {
    const { data, error } = await Profile.userInvoices();
    if (error) return Notification('warning', error);
    setInvoiceData(data);
    setHistoryModalVisible(true);
    setBalance(Profile.getTotalBalance());
    setCoins(Profile.getUserCoins())
  }

  return (
    <>
      <HistoryModal data={invoiceData} isHistoryModalVisible={isHistoryModalVisible} setHistoryModalVisible={setHistoryModalVisible} infoHeader={"????? "} infoText={"????"}></HistoryModal>
      <InfoModal isInfoModalVisible={isInfoModalVisible} setInfoModalVisible={setInfoModalVisible} infoHeader={"Выберите платежную систему и укажите реквизиты для вывода"} infoText2={"Составьте заявку на главной странице обменного пункта."} infoText1={"Извините, пополнение с помощью банковской карты временно недоступно. Мы решаем данную ситуацию и вскоре исправим."}></InfoModal>
      <SelectCrypto visible={visible} setVisible={setVisible} Profile={Profile}></SelectCrypto>
      {
        width < breakpoints ?
          <>
            <Row style={{ height: "4vh" }}></Row>
            <Row>
              <Col span={2}></Col>
              <Col style={{ textAlign: "center" }}>
                <h1 style={{
                  fontFamily: "Inter",
                  marginLeft: "-2vw",
                  marginTop: "3vw",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10vw",
                  lineHeight: "110%",
                }}>Профиль</h1>
              </Col>
            </Row>
          </>
          :
          <>
            <div style={{ height: "10vh" }}></div>
            <Row>
              <Col span={3}></Col>
              <Col span={5}>
                <h1 style={{
                  fontFamily: "Inter",
                  marginLeft: "1vw",
                  top: "4vw",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "3.8vw",
                  lineHeight: "110%",
                }}>Профиль</h1>
              </Col>
            </Row>
          </>
      }
      {
        width < breakpoints ?
          <Row >
            <Col style={{ padding: '8px 1vw' }} span={width < breakpoints ? balanceInfoGrid.f1 : 1}></Col>
            <Col style={{ padding: '8px 1vw', }} span={width < breakpoints ? balanceInfoGrid.f2 : 22}>
              <View style={{
                height: `${userCoins.length == 0 ? 12 : 12 + (userCoins.length * 11.5)}vh`,
                position: "relative",
                backgroundColor: "#1a237e",
                borderRadius: "10px"
              }}>
                <Row >
                  <Row style={{ height: "12vh", width: "99%", padding: "3vw" }}>
                    <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={20}>
                      <p style={{ color: "white", fontSize: "20px" }}>Баланс {totalBalance}$</p>
                     
                    </Col>
                    <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={4}> <ReloadOutlined style={{ color: "white" }} onClick={() => {
                        setBalance(Profile.getTotalBalance());
                        setCoins(Profile.getUserCoins())
                      }} /> </Col>
                    {/* <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={4}></Col> */}
                  </Row>
                  {userCoins.map((c) => {
                    return <>
                      <Row style={{ width: "99%" }}>
                        <Col style={{ padding: '1px 4vw', backgroundColor: "#1a237e" }} span={8}>
                          <p style={{ color: "white" }}>{c.name} {c.quantity}</p>
                        </Col>
                        {/* <Col span={1}>{c.quantity}</Col> */}
                        <Col style={{ padding: '1px 4vw', color: "white", backgroundColor: "#1a237e" }} span={16}>
                          <p style={{ color: "#FFFFFF", marginLeft: "50%" }}>
                            {c.price_usd} $
                          </p>
                        </Col>
                      </Row>
                      {/* <Row> */}
                        <Col span={1} style={{ padding: '1px 2vw', backgroundColor: "#1a237e" }}></Col>
                        <Col span={5}>
                          <p style={{ color: "gray", fontSize: "12px", }}>Адрес:</p>
                        </Col>
                        <Col style={{ backgroundColor: "#1a237e" }} span={16}>
                          <p style={{ color: "#BCBFC4", fontSize: "12px" }}> {c.cryptoWalletId}</p>
                        </Col>
                      {/* </Row> */}
                    </>
                  })}
                </Row>
              </View>
            </Col>
            <Col style={{ padding: '8px 1vw' }} span={width < breakpoints ? balanceInfoGrid.f3 : 1}></Col>
          </Row>
          :
          <Row>
            <Col style={{ padding: '8px 1vw' }} span={width < breakpoints ? balanceInfoGrid.f1 : 3}></Col>
            <Col style={{ padding: '8px 1vw', borderRadius: "10px" }} span={width < breakpoints ? balanceInfoGrid.f2 : 18}>
              <Row style={{
                height: `${userCoins.length == 0 ? 25 : (userCoins.length * 15) + 24}vh`,
                position: "relative",
                backgroundColor: "#1a237e",
                borderRadius: "10px"
              }}>
                {/* <Row style={{ width: "99%", padding: "1vw" }}>
                  <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={8}>
                    <p style={{ color: "white", fontSize:"30px" }}>Баланс</p>
                  </Col>
                  <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={8}> </Col>
                  <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={8}></Col>
                </Row>
                <Row style={{ width: "99%", padding: "1vw" }}>
                  <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height:"5vh" }} span={6}>
                    <p style={{ color: "gray" }}>Кошелек</p>
                  </Col>
                  <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height:"5vh" }} span={6}>
                    <p style={{ color: "gray" }}>Сумма</p></Col>
                  <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height:"5vh" }} span={12}>
                    <p style={{ color: "gray" }}>Адрес</p></Col>
                </Row> */}
                <Row key={userCoins} style={{ width: "99%", padding: "1vw" }}>
                  <Row style={{ width: "99%" }}>
                    <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={18}>
                      <p style={{ color: "white", fontSize: "30px" }}>Баланс {totalBalance}$</p>
                      <ReloadOutlined style={{ color: "white" }} onClick={() => {
                        setBalance(Profile.getTotalBalance());
                        setCoins(Profile.getUserCoins())
                      }} />
                    </Col>
                    <Col style={{ padding: '8px 1vw', backgroundColor: "#1a237e" }} span={6}> </Col>
                  </Row>
                  <Row style={{ width: "99%" }}>
                    <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height: "5vh" }} span={4}>
                      <p style={{ color: "gray" }}>Кошелек</p>
                    </Col>
                    <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height: "5vh" }} span={5}>
                      <p style={{ color: "gray" }}>Количество</p></Col>
                    <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height: "5vh" }} span={5}>
                      <p style={{ color: "gray" }}>Сумма</p></Col>
                    <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e", height: "5vh" }} span={10}>
                      <p style={{ color: "gray" }}>Адрес</p></Col>
                  </Row>
                  {userCoins.map((c) => {
                    return <>
                      <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e" }} span={4}>
                        <p style={{ color: "white" }}>{c.name}</p>
                      </Col>
                      <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e" }} span={5}>
                        <p style={{ color: "white" }}>{c.price_usd}</p>
                      </Col>
                      <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e" }} span={5}>
                        <p style={{ color: "white" }}>{c.quantity}</p>
                      </Col>
                      <Col style={{ padding: '1px 1vw', backgroundColor: "#1a237e" }} span={10}>
                        <p style={{ color: "white" }}>{c.cryptoWalletId}</p>
                      </Col>
                    </>
                  })}

                </Row>
              </Row>
            </Col>
            <Col style={{ padding: '8px 1vw' }} span={width < breakpoints ? balanceInfoGrid.f3 : 3}></Col>
          </Row>
      }
      {
        width < breakpoints ?
          <>
            <Row >
              <Col span={1}>
              </Col>
              <Col onClick={() => setInfoModalVisible(true)} style={{ padding: '8px 1vw' }} span={11}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }}>
                  <Row>
                    <Col span={2}></Col>
                    <p style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "18px",
                      position: "relative",
                      top: "2vh",
                      lineHeight: "125%",
                    }}>Пополнить</p>
                  </Row>
                </GridView>
              </Col>
              <Col onClick={() => openWithdrawModal()} style={{ padding: '8px 1vw' }} span={11}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }} >
                  <Row>
                    <Col span={3}></Col>
                    <p style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "18px",
                      position: "relative",
                      top: "2vh",
                      lineHeight: "125%",
                    }}>Вывести</p>
                  </Row>
                </GridView>
              </Col>
              <Col span={2}>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: '8px 1vw' }} span={1}></Col>
              <Col style={{ padding: '8px 1vw' }} span={22}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }}>
                  <p style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    position: "relative",
                    top: "2vh",
                    left: "3vw",
                    lineHeight: "125%",
                  }}>Настройки</p>
                  <div style={{ top: "3vh", position: "relative", left: "3vw", width: "75%", height: "30%" }}>
                    <p>{thisUser.firstName}{thisUser.lastName} <br></br><p style={{ color: "#767C86" }}>{thisUser.phone} / {thisUser.email}</p></p>
                  </div>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={1}></Col>
            </Row>
            <Row>
              <Col style={{ padding: '8px 1vw' }} span={1}></Col>
              <Col style={{ padding: '8px 1vw' }} span={11}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }}>
                  <Row onClick={() => window.location.href = "/home"}>
                    <Col span={4}>
                      <p style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "18px",
                        position: "relative",
                        top: "2vh",
                        left: "3vw",
                        lineHeight: "125%",
                      }}>Обменять валюту</p>
                    </Col>
                  </Row>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={11}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }}>
                  <Row onClick={() => openInvoiceHistory()}>
                    <Col span={4}>
                      <p style={{
                        fontFamily: "Inter",
                        cursor: "pointer",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "18px",
                        position: "relative",
                        top: "2vh",
                        left: "3vw",
                        lineHeight: "125%",
                      }}>История операций</p>
                    </Col>
                  </Row>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={1}></Col>
            </Row>
            <Row>
              <Col style={{ padding: '8px 1vw' }} span={1}></Col>
              <Col style={{ padding: '8px 1vw' }} span={11}>
                <GridView style={{ height: width < breakpoints ? "20vh" : "30vh" }}>
                  <Row>
                    <p onClick={() => handleLogOut(setIn)} style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "18px",
                      position: "relative",
                      top: "2vh",
                      left: "3vw",
                      lineHeight: "125%",
                    }}>Выход</p>
                  </Row>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={12}></Col>
            </Row>
          </>
          :
          <>
            <Row>
              <Col style={{ padding: '8px 1vw' }} span={3}></Col>
              <Col style={{ padding: '8px 1vw' }} span={5}>
                <GridView >
                  <Col span={2}>
                  </Col>
                  <Col onClick={() => setInfoModalVisible(true)} style={{ padding: '8px 1vw' }} span={10}>
                    <Row>
                      <Col span={3}></Col>
                      <Col span={6}><p style={{
                        fontFamily: "Inter",
                        cursor: "pointer",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "30px",
                        left: "2vw",
                        top: "2.24vh",
                        lineHeight: "125%",
                      }}>Пополнить</p></Col>
                    </Row>
                  </Col>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={5}>
                <GridView >
                  <Col span={2}>
                  </Col>
                  <Col onClick={() => openWithdrawModal()} style={{ padding: '8px 1vw' }} span={10}>
                    <Row>
                      <Col span={3}></Col>
                      <Col span={6}><p style={{
                        fontFamily: "Inter",
                        cursor: "pointer",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "30px",
                        left: "2vw",
                        top: "2.24vh",
                        lineHeight: "125%",
                      }}>Вывести</p></Col>
                    </Row>
                  </Col>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={8}>
                <GridView >
                  <Row>
                    <Col style={{ padding: '8px 1vw' }} span={10}>
                      <Row>
                        <Col span={1}></Col>
                        <Col span={6}><p style={{
                          fontFamily: "Inter",
                          cursor: "pointer",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "30px",
                          left: "1vw",
                          top: "2.24vh",
                          lineHeight: "125%",
                        }}>Настройки</p></Col>
                      </Row>
                    </Col>
                  </Row>
                  <div style={{ top: "5vh", position: "relative", left: "1.58vw", width: "65%", height: "30%" }}>
                    <p>{thisUser.firstName} {thisUser.lastName} <br></br>{thisUser.phone} / {thisUser.email}</p>
                  </div>
                  <div style={{
                    position: "relative",
                    width: "0.48vw",
                    cursor: "pointer",
                    height: "4vh",
                    left: "78%",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "16px",
                    lineHeight: "135%",
                  }}>
                    <Button onClick={handleLogOut}>Выход</Button>
                  </div>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={3}></Col>
            </Row>
            <Row>
              <Col style={{ padding: '8px 1vw' }} span={3}></Col>
              <Col style={{ padding: '8px 1vw' }} span={5}>
                <GridView >
                  <Col span={2}>
                  </Col>
                  <Col onClick={() => window.location.href = "/home"} style={{ padding: '8px 1vw' }} span={10}>
                    <Row>
                      <Col span={2}></Col>
                      <Col span={6}><p style={{
                        fontFamily: "Inter",
                        cursor: "pointer",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "30px",
                        left: "1vw",
                        top: "2.24vh",
                        lineHeight: "125%",
                      }}>Обменять валюту</p></Col>
                    </Row>
                  </Col>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={9}>
                <GridView >
                  <Col span={2}>
                  </Col>
                  <Col onClick={() => openInvoiceHistory()} style={{ padding: '8px 1vw' }} span={10}>
                    <Row>
                      <Col span={2}></Col>
                      <Col span={6}><p style={{
                        fontFamily: "Inter",
                        cursor: "pointer",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "30px",
                        left: "1vw",
                        top: "2.24vh",
                        lineHeight: "125%",
                      }}>История операций</p></Col>
                    </Row>
                  </Col>
                </GridView>
              </Col>
              <Col style={{ padding: '8px 1vw' }} span={5}></Col>
            </Row>
          </>
      }
      <Row style={{ padding: "5%" }}>
        <Col span={24}></Col>
      </Row>
    </>

  );
}

export default App;

export const InterNormalWhite18px = css`
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl3);
    font-weight: 400;
    font-style: normal;
  `;

export const InterLightWhite14px = css`
    color: #FFFFFF;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m3);
    font-weight: 300;
    font-style: normal;
  `;

export const InterLightWhite18px = css`
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl3);
    font-weight: 300;
    font-style: normal;
  `;

const View = styled.div`
    height: 35vh;
    position: relative;
    background-color: #1a237e;
    border-radius: 10px;
    overflow: hidden;
  `;

const GridView = styled.div`
height: 30vh;
    position: relative;
    border: 1px solid #BCBFC4;
    border-radius: 10px;
    border-color: #BCBFC4;
    box-sizing: border-box;
    overflow: hidden;
  `;
